import React, { Fragment, useEffect, useState } from "react";
import Modal from "components/utils/Modal";
import Select from "react-select";
import LcService from "services/LcServices";

const Create = ({ open, onCloseModal, getLc }) => {
  const [block, setBlock] = useState(false);

  const currency = [
    { value: "usd", label: "USD" },
    { value: "bdt", label: "BDT" },
  ];

  const [file, setFile] = useState("");
  const [data, setData] = useState({
    invoice_to: "",
    invoice_no: "",
    shipment_date: "",
    dispatch_by: "",
    currency: "",
    lc_amount: "",
    tt_amount: "",
    total_amount: 0,
    description: "",
    file: file,
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setBlock(false);

    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    if (data.lc_amount.length && data.tt_amount.length) {
      setData({
        ...data,
        total_amount: parseInt(data.lc_amount) + parseInt(data.tt_amount),
      });
    } else if (data.lc_amount.length) {
      setData({
        ...data,
        total_amount: parseInt(data.lc_amount),
      });
    } else if (data.tt_amount.length) {
      setData({
        ...data,
        total_amount: parseInt(data.tt_amount),
      });
    }else{
      setData({
        ...data,
        total_amount: 0,
      });
    }
  }, [data?.lc_amount, data?.tt_amount]);

  const handleSelect = (option, action) => {
    const value = option.value;
    const name = action.name;
    setData({
      ...data,
      [name]: value,
    });
  };

  const addLc = async () => {
    setBlock(true);
    let formData = new FormData(document.getElementById("lc-create"));
    formData.append('total_amount',data?.total_amount)
    await LcService.create(formData);
    onCloseModal();
    setData({
      invoice_to: "",
      invoice_no: "",
      shipment_date: "",
      dispatch_by: "",
      currency: "",
      lc_amount: "",
      tt_amount: "",
      total_amount: "",
      description: "",
      file: "",
    });
    getLc();
  };

  return (
    <div>
      <Modal
        size="xl"
        open={open}
        onCloseModal={onCloseModal}
        title={<>Add LC</>}
        body={
          <>
            <form id="lc-create" encType="multipart/form-data">
              <div className="mb-5 fv-row fv-plugins-icon-container text-center">
                <div
                  className="fv-plugins-message-container invalid-feedback"
                  htmlFor="image"
                ></div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="required form-label">Invoice To</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Invoice To"
                    name="invoice_to"
                    id="invoice_to"
                    onChange={handleChange}
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>
                <div className="col-6">
                  <label className="required form-label">Invoice No</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Invoice No"
                    name="invoice_no"
                    id="invoice_no"
                    onChange={handleChange}
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>

                <div className="col-6">
                  <label className="required form-label">Shipment Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Enter Shipment Date"
                    name="shipment_date"
                    id="shipment_date"
                    onChange={handleChange}
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>
                <div className="col-6">
                  <label className="required form-label">Dispatch By</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Dispatch By"
                    name="dispatch_by"
                    id="dispatch_by"
                    onChange={handleChange}
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>

                <div className="col-6">
                  <label className="required form-label">Currency</label>
                  <Select
                    options={currency}
                    onChange={(option, action) => handleSelect(option, action)}
                    name="currency"
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>
                <div className="col-6">
                  <label className="required form-label">LC Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Total Amount"
                    name="lc_amount"
                    id="lc_amount"
                    onChange={handleChange}
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>
                <div className="col-6">
                  <label className="required form-label">TT Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter TT Amount"
                    name="tt_amount"
                    id="tt_amount"
                    onChange={handleChange}
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>
                <div className="col-6">
                  <label className="required form-label">Total Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Total Crf Amount"
                    name="total_amount"
                    id="total_amount"
                    value={data?.total_amount}
                    disabled
                    onChange={handleChange}
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>
              </div>

              <div className="form-group mt-5">
                <label className="form-label">Description</label>
                <textarea
                  rows="3"
                  type="text"
                  className="form-control"
                  placeholder="Enter Description"
                  name="description"
                  id="description"
                  onChange={handleChange}
                />
                <div
                  className="fv-plugins-message-container invalid-feedback"
                  htmlFor="description"
                ></div>
              </div>

              <button
                type="button"
                disabled={block}
                className="btn btn-primary mr-2 mt-5"
                style={{ marginRight: "1rem" }}
                onClick={() => {
                  addLc();
                }}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-secondary  mt-5 "
                onClick={onCloseModal}
              >
                Cancel
              </button>
            </form>
          </>
        }
      />
    </div>
  );
};

export default Create;
