import Table from "components/utils/Table";
import Confirmation from "components/utils/Confirmation";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BinLocationService from "services/BinLocationService";
import CreatePartName from "./Create";
import EditPartName from "./Edit";
import PermissionAbility from "helpers/PermissionAbility";
import PartNameService from "services/PartNameService";

const PartName = () => {
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [data, setData] = useState(null);
  const [partName, setPartName] = useState([]);
  const [partNameId, setPartNameId] = useState([]);

  const columns = [
    {
      name: "part Name",
      selector: (row) => row.name,
      sortable: true,
      field: "name",
    },

    {
      name: "part Number",
      selector: (row) => row.part_number,
      sortable: true,
      field: "part_number",
    },

    {
      name: "part Item",
      selector: (row) => row?.parts_count ?? "--",
      sortable: true,
      field: "parts_count",
    },

    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      field: "description",
    },

    {
      name: "Action",
      selector: (row) => row.invoice_no,
      format: (row) => (
        <span className="text-end">
          <PermissionAbility permission="part_name_show">
            <Link
              to={"/panel/part-name/" + row?.id}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </PermissionAbility>
          <PermissionAbility permission="part_name_edit">
            <button
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={() => {
                setData(row);
                setOpenEditModal(true);
              }}
            >
              <i className="fa fa-pen"></i>
            </button>
          </PermissionAbility>
          <PermissionAbility permission="part_name_delete">
            <Link
              to="#"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
              onClick={() => {
                setPartNameId(row?.id);
                setConfirmDelete(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </Link>
          </PermissionAbility>
        </span>
      ),
    },
  ];

  const getPartName = async (filters) => {
    setPartName(await PartNameService.getAll(filters));
    setLoading(false);
  };

  const deletePartName = async (setPartNameId) => {
    await PartNameService.remove(setPartNameId);
    getPartName();
  };

  const onCloseModal = () => {
    setOpenAddModal(false);
    setOpenEditModal(false);
  };

  useEffect(() => {
    getPartName();
  }, []);

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="Part Name"
            buttonName="Add Part Name"
            onClickButton={() => setOpenAddModal(true)}
            isLoading={loading}
            data={partName}
            columns={columns}
            onFilter={getPartName}
          />
        </div>
      </div>
      <CreatePartName
        open={openAddModal}
        onCloseModal={onCloseModal}
        onCreated={getPartName}
      />
      {openEditModal && (
        <EditPartName
          open={openEditModal}
          item={data}
          onCloseModal={onCloseModal}
          onUpdated={getPartName}
        />
      )}
      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deletePartName(partNameId);
        }}
        onCancel={() => setConfirmDelete(false)}
      />
    </>
  );
};

export default PartName;
