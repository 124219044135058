import React, { useState, useEffect } from "react";
import Modal from "../../components/utils/Modal";
import WareHouseService from "../../services/WareHouseService";
import Select from "react-select";
import BinLocationService from "services/BinLocationService";
const EditBinLocation = ({ open, onCloseModal, onUpdated, item }) => {
  const [block, setBlock] = useState(false);
  const [data, setData] = useState();
  const [warehouses, setWarehouses] = useState([]);
  const [defaultWareHouse, setDefaultWarehouse] = useState("");

  useEffect(() => {
    setData(item);
    setDefaultWarehouse({
      value: item?.warehous_id,
      label: item?.warehouse_name?.replaceAll("_", " ")?.capitalize(),
    });
  }, [item]);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSelect = (option, action) => {
    const value = option.value;
    const name = action.name;
    setData({
      ...data,
      [name]: value,
    });
  };
  const updateBinLocation = async () => {
    setBlock(true);
    await BinLocationService.update(data?.id, data);
    onUpdated();
    onCloseModal();
    setData({
      id: "",
      name: "",
      description: "",
      warehous_id: "",
      warehouse_name: "",
    });
    setBlock(false);
  };

  const getWarehouses = async () => {
    setBlock(true);
    let dt = await WareHouseService.getAll();
    dt = dt.map((itm) => ({ label: itm.name, value: itm.id }));
    setWarehouses(dt);
    setBlock(false);
  };

  useEffect(() => {
    getWarehouses();
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onCloseModal={onCloseModal}
        title={<>Edit Warehouse</>}
        body={
          <>
            <form>
              <div className="form-group">
                <label className="required form-label">Warehouses</label>
                <Select
                  options={warehouses}
                  defaultValue={defaultWareHouse}
                  onChange={handleSelect}
                  name="warehouse_id"
                />
              </div>
              <div className="form-group mt-5">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  name="name"
                  id="name"
                  value={data?.name || ""}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group mt-5">
                <textarea
                  rows="3"
                  type="text"
                  className="form-control"
                  placeholder="Enter Description"
                  name="description"
                  id="description"
                  value={data?.description || ""}
                  onChange={handleChange}
                />
              </div>

              <button
                type="reset"
                className="btn btn-primary mr-2 mt-5"
                style={{ marginRight: "1rem" }}
                onClick={updateBinLocation}
              >
                Update
              </button>
              <button
                type="reset"
                className="btn btn-secondary  mt-5 "
                onClick={onCloseModal}
              >
                Cancel
              </button>
            </form>
          </>
        }
      />
    </div>
  );
};

export default EditBinLocation;
