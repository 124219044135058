import http from "../http-common";

const getAll = async (filters) => {
  const res = await http.get("/lc", {
    params: filters,
  });
  return res.data;
};

const get = async (id) => {
  const res = await http.get(`/lc/${id}`);
  return res.data;
};

const create = async (data) => {
  const res = await http.post(`/lc`, data);
  return res.data;
};

const update = async (id, data) => {
  data.append("_method", "PUT");
  const res = await http.post(`/lc/${id}`, data);
  return res.data;
};

const remove = async (id) => {
  const res = await http.delete(`/lc/${id}`);
  return res.data;
};

//file upload
const fileUpload = async (id, data) => {
  const res = await http.post(`/lcs/${id}/files`, data);
  return res.data;
};

//get file
const getFile = async (id) => {
  const res = await http.get(`/lcs/${id}/files`);
  return res.data;
};

//get file
const deleteFile = async (uuid, model_id) => {
  const res = await http.delete(
    `/lcs/${model_id}/files/${uuid}/delete`
    );
    return res.data;
  };


const LcService = {
  getAll,
  get,
  create,
  update,
  remove,
  fileUpload,
  getFile,
  deleteFile
};

export default LcService;
