import Table from "components/utils/Table";
import { useEffect, useState } from "react";
import PartService from "services/PartService";
import PermissionAbility from "helpers/PermissionAbility";

const LcPartSummery = () => {
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [serviceId, setServiceId] = useState([]);
  const [partSummery, setPartSummery] = useState([]);
  const [data, setData] = useState(null);
  const columns = [
    {
      name: "Part Name",
      selector: (row) => row.part_name ?? "--",
      sortable: true,
      field: "name",
    },

    {
      name: "Part Number",
      selector: (row) => row.part_number ?? "--",
      sortable: true,
      field: "part_number",
    },

    {
      name: "LC Invoice",
      selector: (row) => row.lc_invoice ?? "--",
      sortable: true,
      field: "lc_invoice",
    },

    {
      name: "Bin-Location",
      selector: (row) => row.bin_location ?? "--",
      sortable: true,
      field: "bin_location",
    },

    {
      name: "Model",
      selector: (row) => row.machine_model ?? "--",
      sortable: true,
      field: "machine_model",
    },

    {
      name: "Qty",
      selector: (row) => row.qty,
      sortable: true,
      field: "qty",
    },

    {
      name: "Total Amount",
      selector: (row) => row.total_amount ?? "--",
      sortable: true,
      field: "total_amount",
    },
  ];

  const getLcPartSummery = async (filters) => {
    setPartSummery(await PartService.getPartSummery(filters));
    setLoading(false);
  };

  const onCloseModal = () => {
    setOpenAddModal(false);
    setOpenEditModal(false);
  };

  useEffect(() => {
    getLcPartSummery();
  }, []);

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="LC part Summery"
            isLoading={loading}
            data={partSummery}
            columns={columns}
            onFilter={getLcPartSummery}
          />
        </div>
      </div>
    </>
  );
};

export default LcPartSummery;
