import http from "../http-common";

const getAll = async (data) => {
  const res = await http.get(`/quotations`, {
    params: data,
  });
  return res.data;
};

const getAllFoc = async (data) => {
  const res = await http.get(`/foc-quotations`, {
    params: data,
  });
  return res.data;
};

const get = async (id) => {
  const res = await http.get(`/quotations/${id}`);
  return res.data;
};

const items = async (id) => {
  const res = await http.get(`/quotations/${id}/part-items`);
  return res.data;
};

const engineers = async () => {
  const res = await http.get("/quotation/engineers");
  return res.data;
};


const create = async (data) => {
  const res = await http.post(`/quotations`, data);
  return res.data;
};

const update = async (id, data) => {
  const res = await http.put(`/quotations/${id}`, data);
  return res.data;
};

const remove = async (id) => {
  const res = await http.delete(`/quotations/${id}`);
  return res.data;
};

const approve = async (id) => {
  const res = await http.post(`/quotations/approve/${id}`);
  return res.data;
};

const reject = async (id) => {
  const res = await http.post(`/quotations/reject/${id}`);
  return res.data;
};

//file upload
const fileUpload = async (id, data) => {
  const res = await http.post(`/quotations/${id}/files`, data);
  return res.data;
};

//get file
const getFile = async (id) => {
  const res = await http.get(`/quotations/${id}/files`);
  return res.data;
};

//get file
const deleteFile = async (uuid, model_id) => {
  const res = await http.delete(
    `/quotations/${model_id}/files/${uuid}/delete`
    );
    return res.data;
  };
  
  //expected delivey and remarks update for requisiton
  const updateReqInfo = async (id, data) => {
    const res = await http.post(`/quotations/info/${id}`, data);
    return res?.data;
  };

  //service quotation

  const createService = async (data) => {
    const res = await http.post(`/service-quotations`, data);
    return res.data;
  };

  const getAllService = async (data) => {
    const res = await http.get(`/service-quotations`, {
      params: data,
    });
    return res.data;
  };

  const getService = async (id) => {
    const res = await http.get(`/service-quotations/${id}`)
    return res.data;
      
  };

  const removeService = async (id) => {
    const res = await http.delete(`/service-quotations/${id}`);
    return res.data;
  };

const QuotationService = {
  getAll,
  getAllFoc,
  get,
  engineers,
  items,
  create,
  update,
  remove,
  approve,
  reject,
  fileUpload,
  getFile,
  deleteFile,
  updateReqInfo,
  //service
  createService,
  getAllService,
  removeService,
  getService
};

export default QuotationService;
