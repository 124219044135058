import { Activities } from "components/utils/Activities";
import PermissionAbility from "helpers/PermissionAbility";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import InvoiceService from "services/InvoiceService";
import InvoiceServiceItems from "./serviceItems/Index";
import InvoiceCreatePayment from "../paymentHistories/Create";
import PaymentHistores from "../paymentHistories/Index";
const ShowServiceInvoice = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState({});
  const [total, setTotal] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [paymentHistories, setPaymentHistories] = useState([]);
  const [active, setActive] = useState("service_items");
  const [tab, setTab] = useState("service_items");
  const [open, setOpen] = useState(false);

  const getInvoice = async () => {
    let res = await InvoiceService.getService(id);
    setInvoice(res);
  };

  useEffect(() => {
    if (invoice) {
      let total = 0;
      invoice?.part_items?.forEach((element) => {
        total = parseInt(total) + parseInt(element?.amount);
      });
      setTotal(total);
    }
  }, [invoice]);

  useEffect(() => {
    invoice?.part_items?.length == 0 && setActive("payment_histories");
  }, [invoice]);

  const getPaymentHistories = async () => {
    let res = await InvoiceService.getPaymentHistories(id);
    setPaymentHistories(res.data);
    setTotalPayment(
      res?.data?.reduce(
        (partialSum, a) => parseInt(partialSum) + parseInt(a.amount),
        0
      )
    );
  };
  const onCloseModal = () => {
    setOpen(false);
    // setOpenEditModal(false);
  };

  useEffect(() => {
    if (!open) {
      getPaymentHistories();
    }
  }, [open]);

  useEffect(() => {
    if (id) getInvoice();
    getPaymentHistories();
  }, [id]);
  return (
    <>
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="row">
            <div className="col-xl-3">
              <div className="card card-custom">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">
                      <button
                        className="btn btn-sm btn-dark "
                        style={{ marginRight: "0.75rem" }}
                        onClick={() => navigate(-1)}
                      >
                        <i className="fa fa-arrow-left"></i>Back
                      </button>
                      Details
                    </h3>
                  </div>
                </div>

                <div className="card-body py-4">
                  <div className="fw-bolder mt-5">Invoice Number</div>
                  <div className="text-gray-600">{invoice?.invoice_number}</div>

                  <div className="fw-bolder mt-5">Invoice Status</div>
                  <div className="text-gray-600">{invoice?.status}</div>

                  <div className="fw-bolder mt-5">Company</div>
                  <div className="text-gray-600">{invoice?.company?.name}</div>

                  <div className="fw-bolder mt-5">Invoice Date</div>
                  <div className="text-gray-600">
                    <Moment format="D MMMM YYYY">
                      {invoice?.invoice_date}
                    </Moment>
                  </div>
                  <div className="fw-bolder mt-5">Requisition Type</div>
                  <div className="text-gray-600">
                    {invoice?.quotation?.type
                      ?.replaceAll("_", " ")
                      ?.capitalize() ?? "--"}
                  </div>
                  {invoice?.part_items?.length > 0 && (
                    <>
                      <div className="fw-bolder mt-5">Priority</div>
                      <div className="text-gray-600">
                        {invoice?.requisition?.priority?.capitalize() ?? "--"}
                      </div>
                    </>
                  )}
                </div>
                <div className="card-header">
                  {invoice?.part_items?.length > 0 && (
                    <div className="card-title">
                      <PermissionAbility permission="service_invoices_print">
                        <h3 className="card-label">
                          <Link
                            className="btn btn-sm btn-dark "
                            to={
                              "/panel/service-invoices/" + invoice.id + "/print"
                            }
                            style={{ marginRight: "0.75rem" }}
                            target="_blank"
                          >
                            Print
                          </Link>
                        </h3>
                      </PermissionAbility>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-xl-9">
              <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-n2">
                  <li className="nav-item">
                    <a
                      className="nav-link text-active-primary pb-4 active"
                      data-bs-toggle="tab"
                      href="#service_items"
                      onClick={() => {
                        setActive("service_items");
                        setTab("service_items");
                      }}
                    >
                      Service Items
                    </a>
                  </li>
                  <PermissionAbility permission="service_invoices_payment">
                  {invoice?.quotation?.type !== "claim_request" && (
                    <li className="nav-item">
                      <a
                        className="nav-link text-active-primary pb-4"
                        data-bs-toggle="tab"
                        href="#payment_histories"
                        onClick={() => {
                          setActive("payment_histories");
                          setTab("payment_histories");
                        }}
                      >
                        Payment Histories
                      </a>
                    </li>
                  )}
                  </PermissionAbility>

                  <li className="nav-item">
                    <a
                      className={`nav-link text-active-primary pb-4 ${
                        tab == "activities" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#activities"
                      onClick={() => {
                        setActive("payment_histories");
                        setTab("activities");
                      }}
                    >
                      Activities
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  {/* Tabs start from here */}
                  <div
                    className={`tab-pane fade ${
                      tab == "service_items" ? "active show" : ""
                    }`}
                    id="service_items"
                    role="tabpanel"
                  >
                    <InvoiceServiceItems
                      active={active}
                      serviceItems={invoice?.part_items}
                      type={invoice?.quotation?.type}
                      tab={tab}
                    />
                  </div>
                  <div
                    className={`tab-pane fade ${
                      tab == "payment_histories" ? "active show" : ""
                    }`}
                    id="payment_histories"
                    role="tab-panel"
                  >
                    <PaymentHistores
                      setOpen={setOpen}
                      data={paymentHistories}
                    />
                  </div>
                  <Activities logName="invoices" modelId={id} tab={tab} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <InvoiceCreatePayment
        open={open}
        onCloseModal={onCloseModal}
        invoice={invoice}
        due={total - totalPayment}
      />
    </>
  );
};

export default ShowServiceInvoice;
