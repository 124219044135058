import http from "../http-common";

const getAll = async (data) => {
  const res = await http.get(`/requisitions`, {
    params: data
});
  return res.data;
};

const get = async (id) => {
  const res = await http.get(`/requisitions/${id}`);
  return res.data;
};


const create = async (data) => {
  const res = await http.post(`/requisitions`, data) 
  return res.data;
};

const update = async (id, data) => {
  const res = await http.put(`/requisitions/${id}`, data);
  return res.data;
};

const remove = async (id) => {
  const res = await http.delete(`/requisitions/${id}`);
  return res.data;
};

const locked = async (data)=>{
  const res =await http.post('/requisitions/locked',data);
  return res?.data;
}

const approve = async (id)=>{
  const res =await http.post(`/requisitions/approve/${id}`);
  return res?.data;
}

const reject = async (id)=>{
  const res =await http.post(`/requisitions/reject/${id}`);
  return res?.data;
}

// quotation comment 
const getComment = async (id) => {
  const res = await http.get(`/requisition-comment/index/${id}`);
  return res.data;
};


//file upload
const fileUpload = async (id, data) => {
  const res = await http.post(`/requisitions/${id}/files`, data);
  return res.data;
};

//get file
const getFile = async (id) => {
  const res = await http.get(`/requisitions/${id}/files`);
  return res.data;
};

//get file
const deleteFile = async (uuid, model_id) => {
  const res = await http.delete( `/requisitions/${model_id}/files/${uuid}/delete`);
  return res.data;
};


const sendComment = async (data) => {
  const res = await http.post(`/requisition-comment`,data); 
  return res.data;

};

const RequisitionService = {
  getAll,
  get,
  create,
  update,
  remove,
  locked,
  getComment,
  sendComment,
  approve,
  reject,
  fileUpload,
  getFile,
  deleteFile

};

export default RequisitionService;