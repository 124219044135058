import http from "../http-common";

const getAll = async (filters) => {
  const res = await http.get("/part-name", {
    params: filters,
  });
  return res.data;
};

const get = async (id) => {
  const res = await http.get(`/part-name/${id}`);
  return res.data;
};

const create = async (data) => {
  const res = await http.post(`/part-name`, data);
  return res.data;
};

const update = async (id, data) => {
  const res = await http.put(`/part-name/${id}`, data);
  return res.data;
};

const remove = async (id) => {
  const res = await http.delete(`/part-name/${id}`);
  return res.data;
};

const getQty = async (id) => {
  const res = await http.get(`/part-name-qty/${id}`);
  return res.data;
};

const PartNameService = {
  getAll,
  get,
  create,
  update,
  remove,
  getQty
};

export default PartNameService;
