import Confirmation from "components/utils/Confirmation";
import Table from "components/utils/Table";
import PermissionAbility from "helpers/PermissionAbility";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PartService from "services/PartService";
import CreateContract from "./Create";
import EditPart from "./Edit";
import ImportFile from "./ImportFile";
import PartFilter from "./PartFilter";

const Parts = () => {
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [parts, setParts] = useState([]);
  const [partId, setPartId] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [part, setPart] = useState(null);
  const [enableFilter, setEnableFilter] = useState(false);
  const [filter, setFilter] = useState({});

  const columns = [
    {
      name: "Name",
      selector: (row) => row.part_name_id ?? "--",
      sortable: true,
      width: "25%",
      wrap: true,
      field: "part_name_id",
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
            <span className="symbol-label bg-light">
              <img
                src={row?.image_url}
                className="h-75 overflow-hidden"
                alt={row?.part_name?.name}
              />
            </span>
          </div>
          <div className="d-flex justify-content-start flex-column">
            <Link
              to={"/panel/part-name/" + row?.part_name?.id}
              className="text-dark fw-bolder text-hover-primary"
            >
              {row.part_name?.name}({row.part_name?.part_number})
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "LC",
      width: "10%",
      selector: (row) => row.lc?.invoice_no ?? "--",
      sortable: true,
      field: "lc",
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column">
            <Link
              to={"/panel/lc/" + row?.lc?.id}
              className="text-dark fw-bolder text-hover-primary"
            >
              {row.lc?.invoice_no}
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "Bin/Location",
      selector: (row) => row.bin_location?.name,
      sortable: true,
      field: "name",
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column">
            <Link
              to={"/panel/bin-location/" + row?.bin_location?.id}
              className="text-dark fw-bolder text-hover-primary"
            >
              {row?.bin_location?.name}
            </Link>
          </div>
        </div>
      ),
    },

    {
      name: "Machine Model",
      selector: (row) => row.machine_model?.name,
      sortable: true,
      field: "name",
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column">
            <Link to="#" className="text-dark fw-bolder text-hover-primary">
              {row?.machine_model?.name}
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => row.qty ?? "--",
      sortable: true,
      field: "qty",
    },
    {
      name: "Selling Price",
      selector: (row) => row.unit_price ?? "--",
      sortable: true,
      field: "unit_price ",
    },
    {
      name: "Action",
      selector: (row) => row.part_name_id,
      maxWidth: "150px",
      format: (row) => (
        <span className="text-end">
          <PermissionAbility permission="parts_show">
            <Link
              to={"/panel/parts/" + row.id}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </PermissionAbility>

          {/* <PermissionAbility permission="parts_edit">
            <button
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={() => {
                setPart(row);
                setOpenEditModal(true);
              }}
            >
              <i className="fa fa-pen"></i>
            </button>
          </PermissionAbility> */}

          <PermissionAbility permission="parts_delete">
            <Link
              to="#"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
              onClick={() => {
                setPartId(row.id);
                setConfirmDelete(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </Link>
          </PermissionAbility>
        </span>
      ),
    },
  ];

  const filterData = (dt) => {
    setFilter({
      ...filter,
      ...dt,
    });

    setEnableFilter(false);
  };

  const getParts = async (filters) => {
    const data = await PartService.getAll(filters);
    setParts(data);
    setLoading(false);
  };

  const deletePart = (partId) => {
    PartService.remove(partId);
    getParts();
    setLoading(false);
  };

  const onCloseModal = () => {
    setOpenAddModal(false);
    setOpenEditModal(false);
    setOpenImportModal(false);
  };

  useEffect(() => {
    if (filter.order)
      //Just to avoid double load
      getParts(filter);
  }, [filter]);

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="Parts"
            buttonName="Add Part"
            search
            onClickButton={() => setOpenAddModal(true)}
            buttonPermission="parts_create"
            callbackButtons={[
              {
                name: "Filter",
                callback: () => {
                  setEnableFilter(!enableFilter);
                },
                permission: null,
              },
              {
                name: "Import",
                callback: () => {
                  setOpenImportModal(true);
                },
                permission: null,
              },
            ]}
            isLoading={loading}
            data={parts}
            columns={columns}
            onFilter={filterData}
          />
        </div>
      </div>

      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deletePart(partId);
        }}
        onCancel={() => setConfirmDelete(false)}
      />
      <CreateContract
        open={openAddModal}
        onCloseModal={onCloseModal}
        onCreated={getParts}
      />
      <EditPart
        open={openEditModal}
        part={part}
        onCloseModal={onCloseModal}
        onUpdated={getParts}
      />
      <ImportFile
        open={openImportModal}
        onCloseModal={onCloseModal}
        onImported={getParts}
      />

      <PartFilter
        enable={enableFilter}
        onClickOutside={() => {
          setEnableFilter(!filter);
        }}
        onChange={filterData}
      />
    </>
  );
};

export default Parts;
