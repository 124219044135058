import React, { Fragment, useEffect, useState } from "react";
import Modal from "components/utils/Modal";
import MachineService from "services/MachineService";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import PartService from "services/PartService";
import Tags from "components/utils/Tags";
import PartNameService from "services/PartNameService";
import LcService from "services/LcServices";
import WareHouseService from "services/WareHouseService";
import BinLocationService from "services/BinLocationService";
import MachineModelService from "services/MachineModelService";

const CreatePart = ({ open, onCloseModal, onCreated }) => {
  const [lc, setLc] = useState([]);
  const [binLocation, setBinLocation] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [machines, setMachines] = useState([]);
  const [partName, setPartName] = useState([]);

  const handlePartAdd = () => {
    setInputField([...inputField, {}]);
  };

  const handlePartRemove = (index) => {
    const list = inputField?.filter((item, ind) => {
      return ind != index;
    });
    setInputField(list);
  };

  const [data, setData] = useState({
    image: "",
    lc_id: "",
    warehouse_id: "",
    bin_location_id: "",
    machine_id: "",
    machine_model_id: "",
    part_name_id: "",
    part_number: "",
    buying_price: "",
    unit_price: "",
    qty: "",
    description: "",
  });
  const [inputField, setInputField] = useState([{}]);
  const [block, setBlock] = useState(false);

  const getPreviousQnty = async () => {
    let itemData = inputField?.filter(
      (item) => item?.machine_id && item?.machine_model_id
    );
    itemData = itemData?.map((item) => {
      return {
        part_name_id: data?.part_name_id,
        machine_id: item?.machine_id,
        machine_model_id: item?.machine_model_id,
      };
    });
    const res = await PartService.previousQuantity(itemData);
    const updateData = inputField?.map((item, index) => {
      return {
        ...item,
        stock_quantity: res[index]?.quantity,
        stock_unit_price: res[index]?.unit_price,
      };
    });
    setInputField(updateData);
  };

  // Set the selected image to preview
  const setImage = async (e) => {
    let logoShow = document.getElementById("logo");
    let fr = new FileReader();
    fr.readAsDataURL(e.target.files[0]);

    fr.addEventListener("load", function () {
      logoShow.style.backgroundImage = "url(" + this.result + ")";
    });
  };

  const setPartImage = async (e, index) => {
    let logoShow = document.getElementById(`logo${index}`);
    let fr = new FileReader();
    fr.readAsDataURL(e.target.files[0]);

    fr.addEventListener("load", function () {
      logoShow.style.backgroundImage = "url(" + this.result + ")";
    });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setBlock(false);

    setData({
      ...data,
      [name]: value,
    });
    if (name == "part_name_id") setInputField([{}]);
  };

  const handlePartChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputField];

    list[index][name] = value;
    setInputField(list);
  };

  const handleSelect = (option, action, index) => {
    const value = option.value;
    const name = action.name;
    const list = [...inputField];
    list[index][name] = value;
    if (name == "machine_id") list[index]["quantity"] = 0;
    setInputField(list);
    if (name == "machine_model_id") {
      getPreviousQnty();
    }
  };

  const addPart = async () => {
    setBlock(true);
    let temp1 = [];

    inputField.forEach((value) => {
      temp1.push({
        bin_location_id: value.bin_location_id,
        warehouse_id: value.warehouse_id,
        machine_id: value.machine_id,
        machine_model_id: value.machine_model_id,
        part_number: value.part_number,
        buying_price: value.buying_price,
        unit_price: value.unit_price,
        total: value.total,
        qty: value.qty,
        description: value.description,
      });
    });

    // let formData = new FormData();
    let formData = new FormData(document.getElementById("create-part"));
    formData.append("lc_id", data.lc_id);
    formData.append("part_name_id", data.part_name_id);
    formData.append("bin_location_id", data.bin_location_id);
    formData.append("warehouse_id", data.warehouse_id);
    formData.append("parts", JSON.stringify(temp1));
    await PartService.create(formData);
    onCreated();
    onCloseModal();
    setBlock(false);
  };

  const getLc = async (filter) => {
    setBlock(true);
    let data = await LcService.getAll(filter);
    data = data?.data?.map((itm) => ({ label: itm.invoice_no, value: itm.id }));
    setLc(data);
    setBlock(false);
  };

  const getWarehouses = async (filter) => {
    setBlock(true);
    let data = await WareHouseService.getAll(filter);
    data = data?.map((itm) => ({ label: itm.name, value: itm.id }));
    setWarehouse(data);
    setBlock(false);
  };

  const getBinLocation = async (filter) => {
    setBlock(true);
    let data = await BinLocationService.getAll(filter);
    data = data?.data?.map((itm) => ({ label: itm.name, value: itm.id }));
    setBinLocation(data);
    setBlock(false);
  };

  const getMachines = async () => {
    setBlock(true);
    let data = await MachineService.allMachines();
    data = data?.map((itm) => ({ label: itm.name, value: itm.id })); //Parse the data as per the select requires
    setMachines(data);
    setBlock(false);
  };

  useEffect(() => {
    if (data?.part_name_id) getMachines();
  }, [data?.part_name_id]);

  const getMachineModel = async (machineId, index) => {
    setBlock(true);
    const list = [...inputField];
    const modelList = list
      ?.filter((item) => item?.machine_model_id)
      .map((item) => item?.machine_model_id);
    let data = await MachineModelService.getAll(machineId);
    const models = data
      ?.filter(
        (item) =>
          (item?.machine?.id == machineId && !modelList.includes(item.id)) ||
          item?.machine?.id != machineId
      )
      .map((itm) => ({
        label: itm.name,
        value: itm.id,
      }));
    list[index]["machineModel"] = models;
    setInputField(list);
    setBlock(false);
  };

  const getPartName = async (filters) => {
    setBlock(true);
    let data = await PartNameService.getAll(filters);
    data = data?.data?.map((itm) => ({
      label: itm.name + "(" + itm.part_number + ")",
      value: itm.id,
    }));
    setPartName(data);
    setBlock(false);
  };

  useEffect(() => {
    //Prevent preload data while modal is hidden
    if (open) getPartName();
    getLc();
    getWarehouses();
    getBinLocation();
    setBlock(false);
  }, [open]);

  return (
    <div>
      <Modal
        size="xl"
        open={open}
        onCloseModal={onCloseModal}
        title={<>Add Part</>}
        body={
          <>
            <form id="create-part" encType="multipart/form-data">
              <div className="mb-5 fv-row fv-plugins-icon-container text-center">
                <div
                  className="mx-auto image-input image-input-outline image-input-changed"
                  data-kt-image-input="true"
                >
                  <div
                    id="logo"
                    className="image-input-wrapper w-125px h-125px"
                    style={{
                      backgroundImage:
                        "url(/assets/media/svg/files/blank-image.svg)",
                    }}
                  ></div>
                  <label
                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="change"
                    data-bs-toggle="tooltip"
                    data-bs-original-title="Change avatar"
                  >
                    <i className="bi bi-pencil-fill fs-7"></i>
                    <input
                      type="file"
                      name="image"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => {
                        setImage(e);
                        handleChange(e);
                      }}
                    />
                  </label>
                </div>
                <div
                  className="fv-plugins-message-container invalid-feedback"
                  htmlFor="image"
                ></div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="required form-label">Name</label>
                  <Select
                    options={partName}
                    onChange={(e) => {
                      handleChange({
                        target: { name: "part_name_id", value: e.value },
                      });
                    }}
                    name="part_name_id"
                    id="part_name_id"
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>

                <div className="col-6">
                  <label className="required form-label">LC</label>
                  <Select
                    options={lc}
                    onChange={(e) => {
                      handleChange({
                        target: { name: "lc_id", value: e.value },
                      });
                    }}
                    name="lc_id"
                    id="lc_id"
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="form-label">Bin/Location</label>
                  <Select
                    options={binLocation}
                    onChange={(e) => {
                      handleChange({
                        target: { name: "bin_location_id", value: e.value },
                      });
                    }}
                    name="bin_location_id"
                    id="bin_location_id"
                  />
                </div>

                <div className="col-6">
                  <label className="form-label">Warehouse</label>
                  <Select
                    options={warehouse}
                    onChange={(e) => {
                      handleChange({
                        target: { name: "warehouse_id", value: e.value },
                      });
                    }}
                    name="warehouse_id"
                    id="warehouse_id"
                  />
                </div>
              </div>
              {inputField?.map((item, index) => (
                <Fragment key={index}>
                  <div className="form-group border rounded p-4 mt-2 mb-2">
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <div className="row">
                          <div className="col-sm-6 col-md-6">
                            {" "}
                            <label className="required form-label">
                              Machine
                            </label>
                            <Select
                              options={machines}
                              value={
                                machines?.find(
                                  (item) =>
                                    item?.value == inputField[index]?.machine_id
                                ) ?? null
                              }
                              onChange={(option, action) => {
                                getMachineModel(option.value, index);
                                handleSelect(option, action, index);
                              }}
                              name="machine_id"
                            />
                            <div
                              className="fv-plugins-message-container invalid-feedback"
                              htmlFor="machine_id"
                            ></div>
                          </div>

                          <div className="col-sm-6 col-md-6">
                            {" "}
                            <label className="required form-label">
                              Machine Model
                            </label>
                            <Select
                              options={inputField[index].machineModel}
                              value={
                                inputField[index].machineModel?.find(
                                  (item) =>
                                    item?.value ==
                                    inputField[index]?.machine_model_id
                                ) ?? null
                              }
                              onChange={(option, action) => {
                                handleSelect(option, action, index);
                              }}
                              name="machine_model_id"
                            />
                            <div
                              className="fv-plugins-message-container invalid-feedback"
                              htmlFor="machine_model_id"
                            ></div>
                          </div>

                          {item?.machine_model_id && (
                            <div className="col-12 border rounded mb-4">
                              <label className="form-label">
                                Previous Stock
                              </label>
                              {item?.stock_quantity > 0 ? (
                                <div className="row">
                                  <div className="col-4">
                                    <label className="required form-label">
                                      Quantity
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Enter Quantity"
                                      name="qty"
                                      id="qty"
                                      value={item?.stock_quantity}
                                      disabled={true}
                                    />
                                    <div
                                      className="fv-plugins-message-container invalid-feedback"
                                      htmlFor="name"
                                    ></div>
                                  </div>

                                  <div className="col-4">
                                    <label className="required form-label">
                                      Total
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="total"
                                      id="total"
                                      value={
                                        item?.stock_quantity *
                                        item?.stock_unit_price
                                      }
                                      disabled={true}
                                    />
                                    <div
                                      className="fv-plugins-message-container invalid-feedback"
                                      htmlFor="name"
                                    ></div>
                                  </div>

                                  <div className="col-4">
                                    <label className="required form-label">
                                      Unit Price
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Enter Buying Price"
                                      name="buying_price"
                                      id="buying_price"
                                      value={item?.stock_unit_price}
                                      disabled={true}
                                    />
                                    <div
                                      className="fv-plugins-message-container invalid-feedback"
                                      htmlFor="name"
                                    ></div>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <label className="d-flex justify-content-center text-danger p-4">
                                    No stock available
                                  </label>
                                </div>
                              )}
                            </div>
                          )}

                          <div className="col-4">
                            <label className="required form-label">
                              Quantity
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Quantity"
                              name="qty"
                              id="qty"
                              onChange={(e) => handlePartChange(e, index)}
                            />
                            <div
                              className="fv-plugins-message-container invalid-feedback"
                              htmlFor="name"
                            ></div>
                          </div>

                          <div className="col-4">
                            <label className="required form-label">Total</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Total"
                              name="total"
                              id="total"
                              onChange={(e) => {
                                handlePartChange(e, index);
                                handlePartChange(
                                  {
                                    target: {
                                      name: "buying_price",
                                      value: Math.floor(
                                        (parseInt(
                                          item?.stock_quantity *
                                            item?.stock_unit_price
                                        ) +
                                          parseInt(item?.total)) /
                                          (parseInt(item?.qty) +
                                            parseInt(item?.stock_quantity))
                                      ),
                                    },
                                  },
                                  index
                                );
                              }}
                            />
                            <div
                              className="fv-plugins-message-container invalid-feedback"
                              htmlFor="name"
                            ></div>
                          </div>

                          <div className="col-4">
                            <label className="required form-label">
                              Unit Price
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Unit Price"
                              name="buying_price"
                              id="buying_price"
                              value={Math.floor(
                                parseInt(item?.total) / parseInt(item?.qty)
                              )}
                              onChange={(e) => {
                                handlePartChange(e, index);
                              }}
                              disabled={true}
                            />
                            <div
                              className="fv-plugins-message-container invalid-feedback"
                              htmlFor="name"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <label className="required form-label">
                          Estimate Buying Price
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Unit Price"
                          name="buying_price"
                          id="buying_price"
                          value={Math.floor(
                            (parseInt(
                              item?.stock_quantity * item?.stock_unit_price
                            ) +
                              parseInt(item?.total)) /
                              (parseInt(item?.qty) +
                                parseInt(item?.stock_quantity))
                          )}
                          disabled={true}
                        />
                        <div
                          className="fv-plugins-message-container invalid-feedback"
                          htmlFor="name"
                        ></div>
                      </div>
                      <div className="col-6">
                        <label className="required form-label">
                          Selling Price
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Unit Price"
                          name="unit_price"
                          id="unit_price"
                          onChange={(e) => handlePartChange(e, index)}
                        />
                        <div
                          className="fv-plugins-message-container invalid-feedback"
                          htmlFor="name"
                        ></div>
                      </div>

                      <div className="form-group mt-5">
                        <label className="form-label">Description</label>
                        <textarea
                          rows="3"
                          type="text"
                          className="form-control"
                          placeholder="Enter Description"
                          name="description"
                          id="description"
                          onChange={(e) => handlePartChange(e, index)}
                        />
                        <div
                          className="fv-plugins-message-container invalid-feedback"
                          htmlFor="description"
                        ></div>
                      </div>

                      <div className="col-sm-1 mt-8">
                        <button
                          type="button"
                          className="btn btn-danger float-right"
                          onClick={() => handlePartRemove(index)}
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </Fragment>
              ))}

              <button
                type="button"
                className="btn btn-primary mr-2 mt-5"
                style={{ marginRight: "1rem" }}
                onClick={handlePartAdd}
              >
                <i className="fa fa-plus"></i> Add More
              </button>
              {/* )} */}
              <button
                type="button"
                disabled={block}
                className="btn btn-primary mr-2 mt-5"
                style={{ marginRight: "1rem" }}
                onClick={() => {
                  addPart();
                }}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-secondary  mt-5 "
                onClick={onCloseModal}
              >
                Cancel
              </button>
            </form>
          </>
        }
      />
    </div>
  );
};

export default CreatePart;
