import React, { useState, useEffect } from "react";
import { Activities } from "components/utils/Activities";
import { useParams, useNavigate } from "react-router-dom";
import ServicesService from "services/ServicesService";

const Show = () => {
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [tab, setTab] = useState("activities");
  let { id } = useParams();
  const navigate = useNavigate();

  const getService = async () => {
    setLoading(true);
    setServices(await ServicesService.get(id));
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      getService();
    }
  }, [id]);

  return (
    <div className="d-flex flex-column-fluid">
      <div className="container">
        <div className="row">
          <div className="col-xl-4">
            <div className="card card-custom">
              <div className="card-header h-auto py-4">
                <div className="card-title">
                  <h3 className="card-label">
                    <button
                      className="btn btn-sm btn-dark "
                      style={{ marginRight: "0.75rem" }}
                      onClick={() => navigate(-1)}
                    >
                      <i className="fa fa-arrow-left"></i>Back
                    </button>
                    Details
                  </h3>
                </div>
              </div>

              <div className="card-body py-4">
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Name:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {services?.name}
                    </span>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Amount:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {services?.amount}
                    </span>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Description:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {services?.description}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="flex-lg-row-fluid ms-lg-15">
              <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
                <li className="nav-item">
                  <a
                    className={`nav-link text-active-primary pb-4 active`}
                    data-bs-toggle="tab"
                    href="#activities"
                    onClick={() => setTab("activities")}
                  >
                    Activities
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <Activities logName="services" modelId={id} tab={tab} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Show;
