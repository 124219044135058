import Confirmation from "components/utils/Confirmation";
import Table from "components/utils/Table";
import PermissionAbility from "helpers/PermissionAbility";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LcService from "services/LcServices";
import CreateLC from "./Create";
import EditLc from "./Edit";

const Lc = () => {
  const [loading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [lc, setLc] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [lcId, setLcId] = useState(null);
  const [data, setData] = useState(null);


  //Set the columns
  const columns = [
    
    {
      name: 'Invoice No',
      selector: row => row.invoice_no,
      sortable: true,
      field: 'invoice_no',
    },

    {
      name: 'Invoice To',
      selector: row => row.invoice_to,
      sortable: true,
      field: 'invoice_to',
    },

    {
      name: "Shipment Date",
      selector: (row) => row.shipment_date,
      sortable: true,
      field: "shipment_date",
    },
    
    {
      name: 'Action',
      selector: row => row.invoice_no,
      format: row => (
        <span className="text-end">
          <PermissionAbility permission="lc_show">
          <Link
            to={"/panel/lc/" + row?.id}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
          >
            <i className="fa fa-eye"></i>
          </Link>
          </PermissionAbility>
          <PermissionAbility permission="lc_edit">
          <button
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            onClick={() => {
              setData(row);
              setOpenEditModal(true);
            }}
          >
            <i className="fa fa-pen"></i>
          </button> 
          </PermissionAbility>
          <PermissionAbility permission="lc_delete">
          <Link
            to="#"
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            onClick={() => {setLcId(row?.id);setConfirmDelete(true)}}
          >
            <i className="fa fa-trash"></i>
          </Link>
          </PermissionAbility>
        </span>
      )
    },
  ];

  const getLc = async (filters) => {
    setLc(await LcService.getAll(filters));
    setLoading(false);
  };

  const deleteLc = async(lcId) => {   
    await LcService.remove(lcId);
    getLc();
  };

  const onCloseModal = () => {
    setOpenAddModal(false);
    setOpenEditModal(false);
  };

  useEffect(() => {
    getLc();
  }, []);

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="lc-details"
            buttonName="Add LC"
            onClickButton={() => setOpenAddModal(true)}
            isLoading={loading} data={lc}
            columns={columns}
            onFilter={getLc}
          />
        </div>
      </div>

      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deleteLc(lcId);
        }}
        onCancel={() => setConfirmDelete(false)}
      />
      
      {openEditModal && <EditLc
        open={openEditModal}
        item={data}
        onCloseModal={onCloseModal}
        onUpdated={getLc}
      />}
      <CreateLC
        open={openAddModal}
        onCloseModal={onCloseModal}
        getLc={getLc}
      />
    </>
  );
};

export default Lc;
