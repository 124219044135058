import Table from "components/utils/Table";
import Confirmation from "components/utils/Confirmation";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BinLocationService from "services/BinLocationService";
import CreateBinLocation from "./Create";
import EditBinLocation from "./Edit";
import PermissionAbility from "helpers/PermissionAbility";

const BinLocation = () => {
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [data, setData] = useState(null);
  const [binLocation, setBinLocation] = useState(false);
  const [binLocationId, setBinLocationId] = useState(false);

  const columns = [
    {
      name: "Bin/Location",
      selector: (row) => row.name,
      sortable: true,
      field: "bin_location",
    },

    {
      name: "Warehouse",
      selector: (row) => row.warehouse_name,
      sortable: true,
      field: "warehouse_name",
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column">
            <Link
              to={"/panel/warehouses/" + row.id}
              className="text-dark fw-bolder text-hover-primary"
            >
              {row.warehouse_name}
            </Link>
          </div>
        </div>
      ),
    },

    {
      name: "Action",
      selector: (row) => row.invoice_no,
      format: (row) => (
        <span className="text-end">
          <PermissionAbility permission="bin_location_show">
            <Link
              to={"/panel/bin-location/" + row?.id}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </PermissionAbility>
          <PermissionAbility permission="bin_location_edit">
            <button
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={() => {
                setData(row);
                setOpenEditModal(true);
              }}
            >
              <i className="fa fa-pen"></i>
            </button>
          </PermissionAbility>
          <PermissionAbility permission="bin_location_delete">
            <Link
              to="#"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
              onClick={() => {
                setBinLocationId(row?.id);
                setConfirmDelete(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </Link>
          </PermissionAbility>
        </span>
      ),
    },
  ];

  const getBinLocation = async (filters) => {
    setBinLocation(await BinLocationService.getAll(filters));
    setLoading(false);
  };

  const deleteBinLocation = async (binLocationId) => {
    await BinLocationService.remove(binLocationId);
    getBinLocation();
  };

  const onCloseModal = () => {
    setOpenAddModal(false);
    setOpenEditModal(false);
  };

  useEffect(() => {
    getBinLocation();
  }, []);

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="bin-location"
            buttonName="Add Bin/Location"
            onClickButton={() => setOpenAddModal(true)}
            isLoading={loading}
            data={binLocation}
            columns={columns}
            onFilter={getBinLocation}
          />
        </div>
      </div>
      <CreateBinLocation
        open={openAddModal}
        onCloseModal={onCloseModal}
        onCreated={getBinLocation}
      />
      {openEditModal && (
        <EditBinLocation
          open={openEditModal}
          item={data}
          onCloseModal={onCloseModal}
          onUpdated={getBinLocation}
        />
      )}
      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deleteBinLocation(binLocationId);
        }}
        onCancel={() => setConfirmDelete(false)}
      />
    </>
  );
};

export default BinLocation;
