import React, { useState, useEffect } from "react";
import { Activities } from "components/utils/Activities";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Table from "components/utils/Table";
import PermissionAbility from "helpers/PermissionAbility";
import BinLocationService from "services/BinLocationService";
import PartService from "services/PartService";

const Show = () => {
  const [loading, setLoading] = useState(false);
  const [binLocation, setBinLocation] = useState([]);
  const [tab, setTab] = useState("parts");
  const [parts, setParts] = useState([]);
  let { id } = useParams();
  const navigate = useNavigate();

  const columns = [
    {
      name: "Name",
      selector: (row) => row.part_name?.name,
      sortable: true,
      field: "name",
      format: (row) => (
        <Link
          to={"/panel/part-name/" + row?.part_name_id}
          className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
        >
          {row?.part_name?.name}
        </Link>
      ),
    },
    {
      name: "Part Number",
      selector: (row) => row.part_name?.part_number ?? "--",
      sortable: true,
      field: "part_number",
    },
    {
      name: "Warehouse",
      selector: (row) => row.warehouse?.name ?? "--",
      sortable: true,
      field: "machine",
      format: (row) => (
        <Link
          to={"/panel/warehouses/" + row?.warehouse_id}
          className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
        >
          {row?.warehouse?.name}
        </Link>
      ),
    },
    {
      name: "Machine Model",
      selector: (row) => row.machine_model?.name ?? "--",
      sortable: true,
      field: "machine_model",
      format: (row) => (
        <Link
          to={
            "/panel/machines/" +
            row?.machine_id +
            "/models/" +
            row?.machine_model_id
          }
          className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
        >
          {row?.machine_model?.name}
        </Link>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.part_name_id,
      maxWidth: "150px",
      format: (row) => (
        <span className="text-end">
          <PermissionAbility permission="bin_location_list_show">
            <Link
              to={"/panel/parts/" + row.id}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </PermissionAbility>
        </span>
      ),
    },
  ];

  const getBinLocation = async () => {
    setLoading(true);
    setBinLocation(await BinLocationService.get(id));
    setLoading(false);
  };

  const getParts = async (filters) => {
    filters.bin_location_id = id;
    setLoading(true);
    let dt = await PartService.getAll(filters);
    setParts(dt);
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      getBinLocation();
    }
  }, [id]);

  return (
    <div className="d-flex flex-column-fluid">
      <div className="container">
        <div className="row">
          <div className="col-xl-4">
            <div className="card card-custom">
              <div className="card-header h-auto py-4">
                <div className="card-title">
                  <h3 className="card-label">
                    <button
                      className="btn btn-sm btn-dark "
                      style={{ marginRight: "0.75rem" }}
                      onClick={() => navigate(-1)}
                    >
                      <i className="fa fa-arrow-left"></i>Back
                    </button>
                    Details
                  </h3>
                </div>
              </div>

              <div className="card-body py-4">
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Bin/Location:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {binLocation?.name}
                    </span>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Warehouse:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {binLocation?.warehouse_name}
                    </span>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Description:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {binLocation?.description}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="flex-lg-row-fluid ms-lg-15">
              <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
                <PermissionAbility permission="bin_location_list">
                  <li className="nav-item">
                    <a
                      className={`nav-link text-active-primary pb-4 ${
                        tab == "parts" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#parts"
                      onClick={() => setTab("parts")}
                    >
                      Parts List
                    </a>
                  </li>
                </PermissionAbility>

                <li className="nav-item">
                  <a
                    className={`nav-link text-active-primary pb-4 ${
                      tab == "activities" ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    href="#activities"
                    onClick={() => setTab("activities")}
                  >
                    Activities
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <PermissionAbility permission="bin_location_list">
                  <div
                    className={`tab-pane fade ${
                      tab == "parts" ? "active show" : "" 
                    }`}
                    id="parts"
                    role="tabpanel"
                  >
                    <div className="card mb-5 mb-xl-8">
                      <div className="card-body py-3">
                        <Table
                          name="Parts"
                          isLoading={loading}
                          data={parts}
                          columns={columns}
                          onFilter={getParts}
                        />
                      </div>
                    </div>
                  </div>
                </PermissionAbility>

                <Activities logName="bin-location" modelId={id} tab={tab} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Show;
