import Modal from "components/utils/Modal";
import { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QuotationService from "services/QuotationService";
toast.configure();

const UpdateReqInfo = ({ open, data, onCloseModal, onUpdated }) => {
  const [updateData, setUpdateDataData] = useState(null);

  const status = [
    { label: "Pending", value: "pending" },
    { label: "On-going", value: "on-going" },
    { label: "Complete", value: "complete" },
  ];

  useEffect(() => {
    setUpdateDataData({
      expected_delivery: data?.expected_delivery,
      type: data?.type,
      status: data?.status,
      remarks: data?.remarks,
    });
  }, [data]);

  const updateInfo = async () => {
    await QuotationService.updateReqInfo(data?.id, updateData);
    onUpdated();
    onCloseModal();
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setUpdateDataData({
      ...updateData,
      [name]: value,
    });
  };
  const handleSelect = (option, action) => {
    const value = option.value;
    const name = action.name;
    setUpdateDataData({
      ...updateData,
      [name]: value,
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onCloseModal={onCloseModal}
        title={<>Update Info</>}
        body={
          <>
            <form id="update-company">
              <div className="mb-5 fv-row fv-plugins-icon-container">
                <label className="form-label">Servicing date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Enter Expected Delivery date"
                  name="expected_delivery"
                  value={updateData?.expected_delivery ?? ""}
                  onChange={handleChange}
                />
                <div
                  className="fv-plugins-message-container invalid-feedback"
                  htmlFor="description"
                ></div>
              </div>

              <div className="col-6">
                <label className="required form-label">Status</label>
                <Select
                  value={status.find(
                    (item) => item.value == updateData?.status
                  )}
                  options={status}
                  onChange={(option, action) => handleSelect(option, action)}
                  name="status"
                />
                <div
                  className="fv-plugins-message-container invalid-feedback"
                  htmlFor="name"
                ></div>
              </div>

              <div className="mb-5 fv-row fv-plugins-icon-container">
                <label className="form-label">Remarks</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter remarks"
                  name="remarks"
                  id="remarks"
                  value={updateData?.remarks ?? ""}
                  onChange={handleChange}
                />
                <div
                  className="fv-plugins-message-container invalid-feedback"
                  htmlFor="description"
                ></div>
              </div>

              <button
                type="reset"
                className="btn btn-primary mr-2 mt-5"
                style={{ marginRight: "1rem" }}
                onClick={updateInfo}
              >
                Update
              </button>
              <button
                type="reset"
                className="btn btn-secondary  mt-5 "
                onClick={onCloseModal}
              >
                Cancel
              </button>
            </form>
          </>
        }
      />
    </div>
  );
};

export default UpdateReqInfo;
