import React, { Fragment, useEffect, useState } from "react";
import Modal from "components/utils/Modal";
import MachineService from "services/MachineService";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import PartService from "services/PartService";
import WareHouseService from "services/WareHouseService";
import BinLocationService from "services/BinLocationService";
import MachineModelService from "services/MachineModelService";

const EditPart = ({ open, part, onCloseModal, onUpdated }) => {
  const [block, setBlock] = useState(false);

  const [data, setData] = useState(null);
  const [img, setImg] = useState(null);
  const [defaults, setDefaults] = useState(null);
  useEffect(() => {
    if (part) {
      setData(part);
      setDefaults({
        name: { label: part?.part_name?.name, value: part?.part_name?.id },
        lc: { label: part?.lc?.invoice_no, value: part?.lc?.id },
        bin: { label: part?.bin_location?.name, value: part?.bin_location?.id },
        warehouse: { label: part?.warehouse?.name, value: part?.warehouse?.id },
        machine: { label: part?.machine?.name, value: part?.machine?.id },
        model: {
          label: part?.machine_model?.name,
          value: part?.machine_model?.id,
        },
      });
    }
  }, [part]);

  const [binLocation, setBinLocation] = useState([]);
  const getBinLocation = async (filter) => {
    setBlock(true);
    let data = await BinLocationService.getAll(filter);
    data = data?.data?.map((itm) => ({ label: itm.name, value: itm.id }));
    setBinLocation(data);
    setBlock(false);
  };

  const [warehouse, setWarehouse] = useState([]);
  const getWarehouses = async (filter) => {
    setBlock(true);
    let data = await WareHouseService.getAll(filter);
    data = data?.map((itm) => ({ label: itm.name, value: itm.id }));
    setWarehouse(data);
    setBlock(false);
  };

  const [machines, setMachines] = useState([]);
  const getMachines = async () => {
    setBlock(true);
    let data = await MachineService.allMachines();
    data = data?.map((itm) => ({ label: itm.name, value: itm.id }));
    setMachines(data);
    setBlock(false);
  };

  useEffect(() => {
    if (open) {
      getBinLocation();
      getWarehouses();
      getMachines();
    }
  }, [open]);

  const [models, setModels] = useState([]);
  const getMachineModel = async (machineId) => {
    setBlock(true);
    const id = machineId ?? defaults?.machine.value;
    let data = await MachineModelService.getAll(id);
    data = data?.map((itm) => ({ label: itm.name, value: itm.id }));
    setModels(data);
    setBlock(false);
  };

  useEffect(() => {
    if (machines) {
      getMachineModel();
    }
  }, [machines]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    setData({ ...data, [name]: checked });
  };

  const updatePart = async () => {
    setBlock(true);
    const partsData = {
      machine_id: data?.machine_id,
      machine_model_id: data?.machine_model_id,
      part_number: data?.part_number,
      buying_price: data?.buying_price,
      unit_price: data?.unit_price,
      qty: data?.qty,
      description: data?.description,
    };

    let formData = new FormData(document.getElementById("part-update"));
    formData.append("img", img);
    formData.append("lc_id", data.lc_id);
    formData.append("part_name_id", data.part_name_id);
    formData.append("bin_location_id", data.bin_location_id);
    formData.append("warehouse_id", data.warehouse_id);
    formData.append("parts", JSON.stringify(partsData));
    formData.append("is_foc", data.is_foc);

    await PartService.update(data?.id, formData);
    onUpdated();
    onCloseModal();
    setBlock(false);
  };

  // Set the selected image to preview
  const setImage = async (e) => {
    let logoShow = document.getElementById("logo");
    let fr = new FileReader();
    fr.readAsDataURL(e.target.files[0]);

    fr.addEventListener("load", function () {
      logoShow.style.backgroundImage = "url(" + this.result + ")";
    });
  };

  return (
    <div>
      <Modal
        size="xl"
        open={open}
        onCloseModal={onCloseModal}
        title={<>Add Part</>}
        body={
          <>
            <form id="part-update" encType="multipart/form-data">
              <div className="mb-5 fv-row fv-plugins-icon-container text-center">
                <div
                  className="mx-auto image-input image-input-outline image-input-changed"
                  data-kt-image-input="true"
                >
                  <div
                    id="logo"
                    className="image-input-wrapper w-125px h-125px"
                    style={{
                      backgroundImage: "url(" + data?.image_url + ")",
                    }}
                  ></div>
                  <label
                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="change"
                    data-bs-toggle="tooltip"
                    data-bs-original-title="Change avatar"
                  >
                    <i className="bi bi-pencil-fill fs-7"></i>
                    <input
                      type="file"
                      name="image"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => {
                        setImage(e);
                        setImg(e.target.files[0]);
                        handleChange(e);
                      }}
                    />
                  </label>
                </div>
                <div
                  className="fv-plugins-message-container invalid-feedback"
                  htmlFor="image"
                ></div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="required form-label">Name</label>
                  <Select
                    defaultValue={defaults?.name}
                    name="part_name_id"
                    id="part_name_id"
                    isDisabled={true}
                  />
                  <div
                    className="fv-plugins-message-container invalid-feedback"
                    htmlFor="name"
                  ></div>
                </div>

                <div className="col-6">
                  <label className="required form-label">LC</label>
                  <Select
                    defaultValue={defaults?.lc}
                    name="lc_id"
                    id="lc_id"
                    isDisabled={true}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="form-label">Bin/Location</label>
                  <Select
                    options={binLocation}
                    defaultValue={defaults?.bin}
                    onChange={(e) => {
                      handleChange({
                        target: { name: "bin_location_id", value: e.value },
                      });
                    }}
                    name="bin_location_id"
                    id="bin_location_id"
                  />
                </div>

                <div className="col-6">
                  <label className="form-label">Warehouse</label>
                  <Select
                    options={warehouse}
                    defaultValue={defaults?.warehouse}
                    onChange={(e) => {
                      handleChange({
                        target: { name: "warehouse_id", value: e.value },
                      });
                    }}
                    name="warehouse_id"
                    id="warehouse_id"
                  />
                </div>
              </div>
              <Fragment>
                <div className="form-group mt-5">
                  <div className="row">
                    <div className="col-sm-6 col-md-6">
                      {" "}
                      <label className="required form-label">Machine</label>
                      <Select
                        options={machines}
                        defaultValue={defaults?.machine}
                        onChange={(e) => {
                          handleChange({
                            target: { name: "machine_id", value: e.value },
                          });
                          getMachineModel(e.value);
                        }}
                        name="machine_id"
                      />
                      <div
                        className="fv-plugins-message-container invalid-feedback"
                        htmlFor="machine_id"
                      ></div>
                    </div>

                    <div className="col-sm-6 col-md-6">
                      {" "}
                      <label className="required form-label">
                        Machine Model
                      </label>
                      <Select
                        name="machine_model_id"
                        options={models}
                        defaultValue={defaults?.model}
                        onChange={(e) => {
                          handleChange({
                            target: {
                              name: "machine_model_id",
                              value: e.value,
                            },
                          });
                        }}
                      />
                      <div
                        className="fv-plugins-message-container invalid-feedback"
                        htmlFor="machine_model_id"
                      ></div>
                    </div>

                    <div className="col-6">
                      <label className="required form-label">
                        Buying Price
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Unit Price"
                        name="buying_price"
                        id="buying_price"
                        value={data?.buying_price}
                        onChange={(e) => handleChange(e)}
                      />
                      <div
                        className="fv-plugins-message-container invalid-feedback"
                        htmlFor="name"
                      ></div>
                    </div>

                    <div className="col-6">
                      <label className="required form-label">
                        Selling Price
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Unit Price"
                        name="unit_price"
                        id="unit_price"
                        value={data?.unit_price}
                        onChange={(e) => handleChange(e)}
                      />
                      <div
                        className="fv-plugins-message-container invalid-feedback"
                        htmlFor="name"
                      ></div>
                    </div>

                    <div className="col-6">
                      <label className="required form-label">QTY</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter QTY"
                        name="qty"
                        id="qty"
                        value={data?.qty}
                        onChange={(e) => handleChange(e)}
                      />
                      <div
                        className="fv-plugins-message-container invalid-feedback"
                        htmlFor="name"
                      ></div>
                    </div>

                    <div className="col-sm-6 col-md-6">
                      <label className="required form-label">Part Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Part Number"
                        name="part_number"
                        id="part_number"
                        value={data?.part_number}
                        onChange={(e) => handleChange(e)}
                      />
                      <div
                        className="fv-plugins-message-container invalid-feedback"
                        htmlFor="part_number[]"
                      ></div>
                    </div>

                    <div className="form-group mt-5">
                      <label className="form-label">Description</label>
                      <textarea
                        rows="3"
                        type="text"
                        className="form-control"
                        placeholder="Enter Description"
                        name="description"
                        id="description"
                        value={data?.description}
                        onChange={(e) => handleChange(e)}
                      />
                      <div
                        className="fv-plugins-message-container invalid-feedback"
                        htmlFor="description"
                      ></div>
                    </div>
                  </div>
                </div>
              </Fragment>

              <button
                type="button"
                disabled={block}
                className="btn btn-primary mr-2 mt-5"
                style={{ marginRight: "1rem" }}
                onClick={() => {
                  updatePart();
                }}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-secondary  mt-5 "
                onClick={onCloseModal}
              >
                Cancel
              </button>
            </form>
          </>
        }
      />
    </div>
  );
};

export default EditPart;
