import Table from "components/utils/Table";
import PermissionAbility from "helpers/PermissionAbility";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RequisitionService from "services/RequisitionService";
import CreateModal from "./CreateModal";
import RequisitionFilter from "./RequisitionFilter";

const Requisitions = () => {
  const [loading, setLoading] = useState(true);
  const [requisitions, setRequisitions] = useState([]);
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [filter, setFilter] = useState(false);

  const filterdata = (data) => {
    setFilter(false);
    getRequisitions(data);
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.rq_number,
      sortable: true,
      field: "id",
      // format: (row) => (
      //   <div className="d-flex align-items-center">

      //     <div className="d-flex justify-content-start flex-column">
      //       <Link
      //         to={"/panel/quotations/" + row?.id}
      //         className="text-dark fw-bolder text-hover-primary"
      //       >
      //         {row?.rq_number}
      //       </Link>
      //     </div>
      //   </div>
      // ),
    },

    {
      name: "Company",
      selector: (row) => row?.company?.name,
      sortable: true,
      field: "name",
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column">
            <Link
              to={"/panel/companies/" + row?.company?.id}
              className="text-dark fw-bolder text-hover-primary"
            >
              {row?.company?.name}
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "Quotation Type",
      selector: (row) =>
        row?.quotation?.type?.replaceAll("_", " ")?.capitalize(),
      sortable: true,
      field: "id",
    },

    {
      name: "Part Quantity",
      selector: (row) =>
        row?.part_items?.reduce((partialSum, a) => partialSum + a.quantity, 0),
      format: (row) => (
        <div className="mt-2">
          {row?.part_items?.reduce(
            (partialSum, a) => partialSum + a.quantity,
            0
          )}
        </div>
      ),
      sortable: true,
      field: "expected_delivery",
    },
    {
      name: "Total",
      selector: (row) =>
        row?.part_items?.reduce(
          (partialSum, a) => partialSum + a.grand_total,
          0
        ),
      format: (row) => (
        <div className="mt-2">
          {row?.requisition?.type != "claim_request"
            ? row?.part_items?.reduce(
                (partialSum, a) => partialSum + parseInt(a.grand_total),
                0
              )
            : 0}{" "}
          Tk.
        </div>
      ),
      sortable: true,
      field: "role",
    },

    {
      name: "lock",
      selector: (row) => row?.locked_at,
      format: (row) => (
        <div className="mt-2">{row?.locked_at ? "Locked" : "Not locked"}</div>
      ),
      sortable: true,
      field: "id",
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      field: "role",
      format: (row) => (
        <>
          {row?.status == "pending" && (
            <div className="mt-2 text-white bg-warning p-1 px-2 rounded">
              Pending
            </div>
          )}
          {row?.status == "approved" && (
            <div className="mt-2 text-white bg-success p-1 px-2 rounded">
              Approved
            </div>
          )}
          {row?.status == "rejected" && (
            <div className="mt-2 text-white bg-danger p-1 px-2 rounded">
              Rejected
            </div>
          )}
          {row?.status == null && (
            <div className="mt-2 text-white bg-success p-1 px-2 rounded">
              --
            </div>
          )}
        </>
      ),
    },

    {
      name: "IN Number",
      selector: (row) => row?.invoice,
      sortable: true,
      field: "id",
      format: (row) => (
        <div className="mt-2">
          {row?.invoice ? row?.invoice : "No invoice yet"}
        </div>
      ),
    },

    {
      name: "Action",
      selector: (row) => row.status,
      format: (row) => (
        <span className="text-end">
          <PermissionAbility permission="requisitions_show">
            <Link
              to={"/panel/requisitions/" + row.id}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </PermissionAbility>
        </span>
      ),
    },
  ];

  const getRequisitions = async (filters) => {
    setRequisitions(await RequisitionService.getAll(filters));
    setLoading(false);
  };
  let navigate = useNavigate();

  const routeChange = () => {
    let path = `create`;
    navigate(path);
  };

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="Requisition"
            buttonName="Filter"
            onClickButton={() => setFilter(!filter)}
            isLoading={loading}
            data={requisitions}
            columns={columns}
            onFilter={getRequisitions}
          />
        </div>
      </div>

      <RequisitionFilter
        onClickOutside={() => {
          setFilter(!filter);
        }}
        enable={filter}
        onChange={(data) => {
          filterdata(data);
        }}
      />
    </>
  );
};

export default Requisitions;
