import Table from "components/utils/Table";
import Confirmation from "components/utils/Confirmation";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreatePartName from "./Create";
import EditService from "./Edit";
import PermissionAbility from "helpers/PermissionAbility";
import ServicesService from "services/ServicesService";

const PartName = () => {
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [serviceId, setServiceId] = useState([]);
  const [services, setServices] = useState([]);
  const [data, setData] = useState(null);

  const columns = [
    {
      name: "Services",
      selector: (row) => row.name,
      sortable: true,
      field: "name",
    },

    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      field: "amount",
    },

    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      field: "description",
    },

    {
      name: "Action",
      selector: (row) => row.invoice_no,
      format: (row) => (
        <span className="text-end">
          <PermissionAbility permission="services_show">
            <Link
              to={"/panel/services/" + row?.id}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </PermissionAbility>
          <PermissionAbility permission="services_edit">
            <button
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              onClick={() => {
                setData(row);
                setOpenEditModal(true);
              }}
            >
              <i className="fa fa-pen"></i>
            </button>
          </PermissionAbility>
          <PermissionAbility permission="services_delete">
            <Link
              to="#"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
              onClick={() => {
                setServiceId(row?.id);
                setConfirmDelete(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </Link>
          </PermissionAbility>
        </span>
      ),
    },
  ];

  const getServices = async (filters) => {
    setServices(await ServicesService.getAll(filters));
    setLoading(false);
  };

  const deletePartName = async (serviceId) => {
    await ServicesService.remove(serviceId);
    getServices();
  };

  const onCloseModal = () => {
    setOpenAddModal(false);
    setOpenEditModal(false);
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="Services"
            buttonName="Add Service"
            buttonPermission="services_create"
            onClickButton={() => setOpenAddModal(true)}
            isLoading={loading}
            data={services}
            columns={columns}
            onFilter={getServices}
          />
        </div>
      </div>
      <CreatePartName
        open={openAddModal}
        onCloseModal={onCloseModal}
        onCreated={getServices}
      />
      {openEditModal && (
        <EditService
          open={openEditModal}
          item={data}
          onCloseModal={onCloseModal}
          onUpdated={getServices}
        />
      )}
      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deletePartName(serviceId);
        }}
        onCancel={() => setConfirmDelete(false)}
      />
    </>
  );
};

export default PartName;
