import http from "../http-common";

const getAll = async (filters) => {
  const res = await http.get("/bin-location", {
    params: filters,
  });
  return res.data;
};

const get = async (id) => {
  const res = await http.get(`/bin-location/${id}`);
  return res.data;
};

const create = async (data) => {
  const res = await http.post(`/bin-location`, data);
  return res.data;
};

const update = async (id, data) => {
  const res = await http.put(`/bin-location/${id}`, data);
  return res.data;
};

const remove = async (id) => {
  const res = await http.delete(`/bin-location/${id}`);
  return res.data;
};

const BinLocationService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default BinLocationService;
