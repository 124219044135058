import React, { useState, useEffect } from "react";
import Modal from "../../components/utils/Modal";
import PartNameService from "services/PartNameService";
import ServicesService from "services/ServicesService";
const EditPartName = ({ open, onCloseModal, onUpdated, item }) => {
  const [block, setBlock] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    setData(item);
  }, [item]);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  const updateService = async () => {
    setBlock(true);
    await ServicesService.update(data?.id, data);
    onUpdated();
    onCloseModal();
    setData({
      id: "",
      name: "",
      amount: "",
      description: "",
    });
    setBlock(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onCloseModal={onCloseModal}
        title={<>Edit Part Name</>}
        body={
          <>
            <form>
              <div className="form-group mt-5">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  name="name"
                  id="name"
                  value={data?.name || ""}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group mt-5">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Amount"
                  name="amount"
                  id="amount"
                  value={data?.amount || ""}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group mt-5">
                <textarea
                  rows="3"
                  type="text"
                  className="form-control"
                  placeholder="Enter Description"
                  name="description"
                  id="description"
                  value={data?.description || ""}
                  onChange={handleChange}
                />
              </div>

              <button
                type="reset"
                className="btn btn-primary mr-2 mt-5"
                style={{ marginRight: "1rem" }}
                onClick={updateService}
              >
                Update
              </button>
              <button
                type="reset"
                className="btn btn-secondary  mt-5 "
                onClick={onCloseModal}
              >
                Cancel
              </button>
            </form>
          </>
        }
      />
    </div>
  );
};

export default EditPartName;
