import React, { useState, useEffect } from "react";
import { Activities } from "components/utils/Activities";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Table from "components/utils/Table";
import PermissionAbility from "helpers/PermissionAbility";
import LcService from "services/LcServices";
import PartService from "services/PartService";
import NewDropzone from "./Dropzone/MyDropzone";
import Confirmation from "components/utils/Confirmation";

const LcShow = () => {
  const [loading, setLoading] = useState(false);
  const [lc, setLc] = useState([]);
  const [tab, setTab] = useState("parts");
  const [parts, setParts] = useState([]);
  let { id } = useParams();
  const navigate = useNavigate();

  const columns = [
    {
      name: "Name",
      selector: (row) => row.part_name?.name,
      sortable: true,
      field: "name",
      format: (row) => (
        <Link
          to={"/panel/part-name/" + row?.part_name_id}
          className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
        >
          {row?.part_name?.name}
        </Link>
      ),
    },
    {
      name: "Part Number",
      selector: (row) => row.part_name?.part_number ?? "--",
      sortable: true,
      field: "part_number",
    },
    {
      name: "Machine",
      selector: (row) => row.machine?.name ?? "--",
      sortable: true,
      field: "machine",
      format: (row) => (
        <Link
          to={"/panel/machines/" + row?.machine_id}
          className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
        >
          {row?.machine?.name}
        </Link>
      ),
    },
    {
      name: "Machine Model",
      selector: (row) => row.machine_model?.name ?? "--",
      sortable: true,
      field: "machine_model",
      format: (row) => (
        <Link
          to={
            "/panel/machines/" +
            row?.machine_id +
            "/models/" +
            row?.machine_model_id
          }
          className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
        >
          {row?.machine_model?.name}
        </Link>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.part_name_id,
      maxWidth: "150px",
      format: (row) => (
        <span className="text-end">
          <PermissionAbility permission="parts_show">
            <Link
              to={"/panel/parts/" + row.id}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </PermissionAbility>
        </span>
      ),
    },
  ];

  //File API

  const [uuid, setuuid] = useState();
  const [model_id, setModelId] = useState();
  const [file, setFile] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const uploadFile = async (formData) => {
    await LcService.fileUpload(id, formData);
    getFile();
  };
  const deleteItem = async () => {
    await LcService.deleteFile(uuid, model_id);
    getFile();
  };
  const getFile = async () => {
    const res = await LcService.getFile(id);
    setFile(res);
  };

  useEffect(() => {
    if (id) getFile();
  }, [id]);

  const getLc = async () => {
    setLoading(true);
    setLc(await LcService.get(id));
    setLoading(false);
  };

  const getParts = async (filters) => {
    filters.lc_id = id;
    setLoading(true);
    let dt = await PartService.getAll(filters);
    setParts(dt);
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      getLc();
    }
  }, [id]);

  return (
    <div className="d-flex flex-column-fluid">
      <div className="container">
        <div className="row">
          <div className="col-xl-4">
            <div className="card card-custom">
              <div className="card-header h-auto py-4">
                <div className="card-title">
                  <h3 className="card-label">
                    <button
                      className="btn btn-sm btn-dark "
                      style={{ marginRight: "0.75rem" }}
                      onClick={() => navigate(-1)}
                    >
                      <i className="fa fa-arrow-left"></i>Back
                    </button>
                    Details
                  </h3>
                </div>
              </div>

              <div className="card-body py-4">
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Invoice No:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {lc?.invoice_no}
                    </span>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Invoice To:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {lc?.invoice_to}
                    </span>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Shipment Date:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {lc?.shipment_date}
                    </span>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Dispatch By:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {lc?.dispatch_by}
                    </span>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Currency:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {lc?.currency}
                    </span>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">LC Amount:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {lc?.lc_amount}
                    </span>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">TT Amount:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {lc?.tt_amount}
                    </span>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Total Amount:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {parseInt(lc?.lc_amount) + parseInt(lc?.tt_amount)}
                    </span>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Description:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {lc?.description}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="flex-lg-row-fluid ms-lg-15">
              <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
                <PermissionAbility permission="lc_part_list">
                  <li className="nav-item">
                    <a
                      className={`nav-link text-active-primary pb-4 ${
                        tab == "parts" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#parts"
                      onClick={() => setTab("parts")}
                    >
                      Parts List
                    </a>
                  </li>
                </PermissionAbility>

                <PermissionAbility permission="lc_files">
                <li className="nav-item">
                  <a
                    className={`nav-link text-active-primary pb-4 ${
                      tab == "activities" ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    href="#files"
                    onClick={() => setTab("files")}
                  >
                    Files
                  </a>
                </li>
                </PermissionAbility>


                <li className="nav-item">
                  <a
                    className={`nav-link text-active-primary pb-4 ${
                      tab == "activities" ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    href="#activities"
                    onClick={() => setTab("activities")}
                  >
                    Activities
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <PermissionAbility permission="lc_part_list"> 
                  <div
                    className={`tab-pane fade ${
                      tab == "parts" ? "active show" : ""
                    }`}
                    id="parts"
                    role="tabpanel"
                  >
                    <div className="card mb-5 mb-xl-8">
                      <div className="card-body py-3">
                        <Table
                          name="Parts"
                          isLoading={loading}
                          data={parts}
                          columns={columns}
                          onFilter={getParts}
                        />
                      </div>
                    </div>
                  </div>
                </PermissionAbility>

                {/* //for files */}
                <div
                  className={`tab-pane fade ${
                    tab == "files" ? "active show" : ""
                  }`}
                  id="files"
                  role="tabpanel"
                >
                  <div className="card card-custom gutter-b">
                    <div className="card-body px-0">
                      <div className="card mb-5 mb-xl-8">
                        <div className="card-body py-3">
                          <form
                            id="attachment-form"
                            encType="multipart/form-data"
                          >
                            <NewDropzone onDrop={uploadFile} />
                          </form>
                          <div className="table-responsive">
                            <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                              <thead>
                                <tr className="fw-bolder text-muted">
                                  <th className="min-w-50px">SL</th>
                                  <th className="min-w-120px">File Name</th>
                                  <th className="min-w-120px">Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {file?.data?.map((item, index) => (
                                  <tr key={index}>
                                    <td className="">{index + 1}</td>
                                    <td className=" fw-bolder mb-1 fs-6">
                                      <span>{item?.file_name}</span>
                                    </td>
                                    <td className=" fw-bolder mb-1 fs-6">
                                      <button
                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                        onClick={() => {
                                          setConfirmDelete(true);
                                          setuuid(item.uuid);
                                          setModelId(item.model_id);
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                      <a
                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                        href={item?.original_url}
                                        target="_blank"
                                      >
                                        <i className="fa fa-download"></i>
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Activities logName="lc" modelId={id} tab={tab} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deleteItem();
        }}
        onCancel={() => setConfirmDelete(false)}
      />
    </div>
  );
};

export default LcShow;
