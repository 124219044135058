import { Activities } from "components/utils/Activities";
import PermissionAbility from "helpers/PermissionAbility";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import CompanyService from "services/CompanyService";
import CompanyInfo from "./sections/Info";
import CompanyUsers from "./users/Index";

const ShowCompany = () => {
  const { id } = useParams();
  const [company, setCompany] = useState({});
  const [active, setActive] = useState("users");
  const getCompany = async () => {
    setCompany(await CompanyService.get(id));
  };

  useEffect(() => {
    getCompany();
  }, [id]);

  return (
    <div className="post d-flex flex-column-fluid" id="kt_post">
      <div id="kt_content_container" className="container-xxl">
        <div className="form d-flex flex-column flex-lg-row gap-7 gap-lg-10 fv-plugins-bootstrap5 fv-plugins-framework">
          <CompanyInfo company={company} />

          <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-n2">
              <li className="nav-item">
                <a
                  className="nav-link text-active-primary pb-4 active"
                  data-bs-toggle="tab"
                  href="#users"
                  onClick={() => {
                    setActive("users");
                  }}
                >
                  Users
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-active-primary pb-4"
                  data-bs-toggle="tab"
                  href="#activities"
                  onClick={() => {
                    setActive("activities");
                  }}
                >
                  Activities
                </a>
              </li>
            </ul>

            <div className="tab-content">
              {/* Tabs start from here */}
              <PermissionAbility permission="companies_users_access">
                <CompanyUsers active={active} companyId={company.id} />
              </PermissionAbility>
              <div
                className="tab-pane fade show"
                id="activities"
                role="tabpanel"
              >
                <div className="card card-xl-stretch mb-xl-10">
                  <Activities logName="companies" modelId={id} tab={active} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowCompany;
