import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import { DebounceInput } from "react-debounce-input";
import Select from "react-select";

import CompanyService from "services/CompanyService";
import PartService from "services/PartService";
import QuotationService from "services/QuotationService";
import { toast } from "react-toastify";

const FOCQuotationCreate = () => {
  const navigate = useNavigate();
  const [block, setBlock] = useState(false);

  const [machineId, setMachineId] = useState([]);
  const [filter, setFilter] = useState({
    part_heading_id: null,
  });
  const [searchData, setSearchData] = useState([]);
  const [list, setList] = useState([]);
  const [selectedPart, setSelectedPart] = useState(false);
  const [totalAmount, setTotal] = useState(0);

  const [companies, setCompanies] = useState([]);
  const getCompanies = async () => {
    let dt = await CompanyService.getAll({
      rows: "all",
    });
    dt = dt.map((itm) => ({ label: itm.name, value: itm.id }));
    setCompanies(dt);
  };

  const [engineers, setEngineers] = useState([]);
  const getEngineers = async () => {
    let dt = await QuotationService.engineers();
    dt = dt.map((itm) => ({ label: itm?.name, value: itm?.id }));
    setEngineers(dt);
  };

  const [data, setData] = useState({
    company_id: "",
    engineer_id: "",
    machine_id: "",
    priority: "",
    type: "claim_request",
    payment_mode: "",
    account_details: "",
    expected_delivery: "",
    payment_term: "",
    payment_partial_mode: "",
    partial_time: "",
    next_payment: "",
    ref_number: "",
    machine_problems: "",
    solutions: "",
    reason_of_trouble: "",
    remarks: "",
    part_items: list,
    total: totalAmount,
    files: [],
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const testData = queryParams.get("data");
    setData(JSON.parse(testData));
  }, []);

  const priorities = [
    { value: "low", label: "Low" },
    { value: "medium", label: "Medium" },
    { value: "high", label: "High" },
  ];

  const payments = [
    { value: "cash", label: "Cash" },
    { value: "bank", label: "Bank" },
    { value: "check", label: "Check" },
    { value: "card", label: "Card" },
  ];

  const payment_terms = [
    { value: "full", label: "Full" },
    { value: "partial", label: "Partial" },
  ];

  const payment_partial_mode = [
    { value: "days", label: "Days" },
    { value: "weeks", label: "Weeks" },
    { value: "months", label: "Months" },
    { value: "years", label: "Years" },
  ];

  const addPart = (item) => {
    item["quantity"] = 0;
    let hasItem = list.find((itm) => itm.id == item.id);
    if (hasItem) return false;

    const newList = list.concat(item);
    setList(Array.from(new Set(newList)));
    setSelectedPart(true);
    setFilter({ ...filter, q: "" });
    setSearchData("");
  };

  const removeItem = (id) => {
    const newList = list.filter((item) => item.id !== id);
    setList(newList);
  };

  const handleSelect = (option, conf) => {
    if (conf.name == "machine_id") {
      const res = option?.map((itm) => itm?.machineId);
      setMachineId(res);
    }

    let value = option.value;
    if (Array.isArray(option))
      value = option.map((dt) => {
        return dt.value;
      });

    const name = conf.name;
    setBlock(false);

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChange = (e) => {
    const { name } = e.target;
    setData({ ...data, [name]: e.target.value });
    setBlock(false);
  };

  const handleDateSelect = (value, name) => {
    setData({
      ...data,
      ...{
        [name]: new Date(value),
        [name + "_format"]: moment(value).format("YYYY/MM/DD"),
      },
    });
    setBlock(false);
  };

  const storeRequisition = async () => {
    setBlock(true);

    let quantity = false;
    data.part_items.forEach((item) => {
      if (item?.quantity == 0) {
        quantity = true;
      }
    });
    if (quantity) {
      toast.warning("Part Quantity can't be 0!");
    } else {
      await QuotationService.create(data);
      navigate("/panel/foc-quotation");
    }
    setBlock(false);
  };

  const getParts = async () => {
    let res = await PartService.getAll({
      ...filter,
    });
    setSearchData(res.data);
    let items = res.data?.map((dt) => {
      return { label: dt.name, value: dt.id };
    });
  };

  const filterData = (e) => {
    let query = e.target.value;
    setFilter({
      ...filter,
      q: query,
    });
  };

  const search = async (e) => {
    await getParts();
    if (filter?.q === "") setSearchData([]);
  };
  useEffect(() => {
    if (filter?.q) {
      search();
    }
  }, [filter, machineId]);

  useEffect(() => {
    setData({ ...data, part_items: list, total: totalAmount }); //add part_items and total amount in data
  }, [list, totalAmount]);

  useEffect(() => {
    getCompanies();
    getEngineers();
  }, []);

  useEffect(() => {
    const sum = list.reduce(
      (partialSum, a) => partialSum + a.unit_price * a.quantity,
      0
    );
    setTotal(sum);
  }, [list]);

  const increment = (item) => {
    const tempList = [...list];
    const tempItem = tempList.filter((val) => val.id === item.id);
    tempItem[0].quantity++;

    setList(tempList);
  };

  const decrement = (item) => {
    const tempList = [...list];
    const tempItem = tempList.filter((val) => val.id === item.id);
    tempItem[0].quantity--;

    setList(tempList);
  };
  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <div className="d-flex flex-column flex-lg-row">
            <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
              <div className="card mb-5">
                <div className="card-body p-12">
                  <form action="" id="kt_invoice_form">
                    <div className="d-flex flex-column align-items-start flex-xxl-row">
                      <div className="d-flex align-items-center flex-equal fw-row me-4 order-2"></div>

                      <div className="d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4">
                        <span className="fs-2x fw-bolder text-gray-800">
                          FOC Quotation
                        </span>
                      </div>

                      <div className="d-flex align-items-center justify-content-end flex-equal order-3 fw-row">
                        <span className="fs-5">
                          Date: {moment().format("DD-MM-YYYY")}
                        </span>
                      </div>
                    </div>

                    <div className="separator separator-dashed my-10"></div>

                    <div className="row gx-10 mb-5">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label className="required form-label">Company</label>
                          <Select
                            options={companies}
                            onChange={handleSelect}
                            name="company_id"
                          />

                          <div
                            className="fv-plugins-message-container invalid-feedback"
                            htmlFor="company_id"
                          ></div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <label className="form-label">Engineer</label>

                        <div className="mb-5">
                          <Select
                            options={engineers}
                            onChange={handleSelect}
                            name="engineer_id"
                          />
                          <div
                            className="fv-plugins-message-container invalid-feedback"
                            htmlFor="engineer_id"
                          ></div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <label className="required form-label">Priority</label>
                        <div className="mb-5">
                          <Select
                            options={priorities}
                            name="priority"
                            onChange={handleSelect}
                          />
                          <div
                            className="fv-plugins-message-container invalid-feedback"
                            htmlFor="priority"
                          ></div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <label className="form-label">Expected Delivery</label>
                        <div className="mb-5">
                          <div className="form-group ">
                            <DatePicker
                              className="form-control"
                              name="expected_delivery"
                              selected={data.expected_delivery}
                              onChange={(date) =>
                                handleDateSelect(date, "expected_delivery")
                              }
                            />
                            <input
                              type="hidden"
                              name="expected_delivery"
                              id="expected_delivery"
                              value={data.expected_delivery}
                            />
                            <div
                              className="fv-plugins-message-container invalid-feedback"
                              htmlFor="expected_delivery"
                            ></div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-8">
                        <label
                          className="form-label fs-6 fw-bolder text-gray-700"
                          htmlFor="types"
                        >
                          Machine Problems
                        </label>
                        <textarea
                          className="form-control form-control-solid mb-3"
                          rows="2"
                          name="machine_problems"
                          data-kt-element="input"
                          placeholder="Type a message"
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="col-lg-6">
                        <label
                          className="form-label fs-6 fw-bolder text-gray-700"
                          htmlFor="types"
                        >
                          Solutions
                        </label>
                        <textarea
                          className="form-control form-control-solid mb-3"
                          rows="5"
                          name="solutions"
                          data-kt-element="input"
                          placeholder="Type a message"
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="col-lg-6">
                        <label
                          className="form-label fs-6 fw-bolder text-gray-700"
                          htmlFor="types"
                        >
                          Reason of Trouble
                        </label>
                        <textarea
                          className="form-control form-control-solid mb-3"
                          rows="5"
                          name="reason_of_trouble"
                          data-kt-element="input"
                          placeholder="Type a message"
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="col-lg-6">
                        <label className="form-label fs-6 fw-bolder text-gray-700">
                          Remarks
                        </label>
                        <textarea
                          name="remarks"
                          className="form-control form-control-solid"
                          rows="5"
                          placeholder="Thanks for your business"
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <span>
            <div className="d-flex flex-column flex-lg-row">
              <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
                <div className="card mb-5">
                  <div className="card-body p-12">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <div
                            className="fv-plugins-message-container invalid-feedback"
                            htmlFor="part_heading_id"
                          ></div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group mt-2">
                          <label htmlFor=""></label>
                          <DebounceInput
                            className="form-control"
                            placeholder="search"
                            debounceTimeout={300}
                            onChange={filterData}
                          />

                          <div>
                            {searchData.length > 0 ? (
                              <div className="card border border-secondary ">
                                <div className="card-body ">
                                  {searchData?.map((item, index) => (
                                    <div key={index}>
                                      <Link
                                        to={item?.id}
                                        style={{ color: "black" }}
                                        onClick={() => addPart(item)}
                                      >
                                        <p>
                                          {item?.part_name?.name}
                                          <span>
                                            ({item.part_name?.part_number})
                                          </span>
                                          <span>
                                            ({item.machine_model?.name})
                                          </span>
                                          <span>({item.qty})</span>
                                        </p>
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!selectedPart && (
              <div className="d-flex flex-column flex-lg-row">
                <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
                  <div className="p-20 bg-white mb-2 text-center">
                    No items selected
                  </div>
                </div>
              </div>
            )}
            {selectedPart && list.length > 0 ? (
              <div className="d-flex flex-column flex-lg-row">
                <div className="flex-lg-row-fluid mb-lg-0 me-lg-7 me-xl-10">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="table-responsive">
                            <table
                              className="table g-5 gs-0 mb-0 fw-bolder text-gray-700"
                              data-kt-element="items"
                            >
                              <thead>
                                <tr className="border-bottom fs-7 fw-bolder text-gray-700 text-uppercase">
                                  <th className="min-w-300px w-475px">Item</th>
                                  <th className="min-w-300px w-475px">
                                    Part Number
                                  </th>
                                  <th className="min-w-100px w-250px">QTY</th>
                                  <th className="min-w-75px w-75px text-end">
                                    Action
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {list?.map((item, index) => (
                                  <tr key={index}>
                                    <td className="pe-7" name="part_name">
                                      {item?.part_name?.name}
                                      <span>({item?.qty})</span>
                                    </td>
                                    <td name="part_number">
                                      {item?.part_name?.part_number}{" "}
                                    </td>

                                    <td className="product-quantity">
                                      <div className="input-group input-group-sm ">
                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text"
                                            id="inputGroup-sizing-sm"
                                            onClick={() => {
                                              if (item?.quantity > 0) {
                                                decrement(item);
                                              }
                                            }}
                                          >
                                            <i className="fas fa-minus"></i>
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          className={
                                            item.quantity == 0
                                              ? "border border-danger rounded form-control"
                                              : "form-control"
                                          }
                                          aria-label="Small"
                                          aria-describedby="inputGroup-sizing-sm"
                                          min="1"
                                          value={item.quantity ?? ""}
                                          defaultValue={item.quantity}
                                          name="quantity"
                                        />

                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text"
                                            onClick={() => increment(item)}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <i className="fas fa-plus"></i>
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-icon btn-danger"
                                        data-kt-element="remove-item"
                                        onClick={() => removeItem(item?.id)}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="separator separator-dashed"></div>
                      <div className="mt-5">
                        <button
                          onClick={() => {
                            storeRequisition();
                          }}
                          className="btn btn-primary"
                          disabled={block}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default FOCQuotationCreate;
