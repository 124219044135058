import { Activities } from "components/utils/Activities";
import Confirmation from "components/utils/Confirmation";
import PermissionAbility from "helpers/PermissionAbility";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import QuotationService from "../../../services/QuotationService";
import NewDropzone from "./Dropzone/MyDropzone";
import UpdateReqInfo from "./section/UpdateReqInfo";
import InvoiceService from "services/InvoiceService";
import { toast } from "react-toastify";

const ShowQuotation = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [file, setFile] = useState({});
  const [tab, setTab] = useState("serviceItems");
  const [block, setBlock] = useState(false);

  const [quotation, setQuotation] = useState({});
  const getQuotation = async () => {
    let res = await QuotationService.getService(id);
    setQuotation(res);
  };

  const [updateDueAMountModal, setUpdateDueAMountModal] = useState(false);
  const onCloseModal = () => {
    setUpdateDueAMountModal(false);
  };
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [uuid, setuuid] = useState();
  const [model_id, setModelId] = useState();

  const [stock, setStock] = useState(true);

  useEffect(() => {
    if (quotation) {
      quotation?.part_items?.forEach((item) => {
        if (item?.quantity > item?.part?.qty) {
          setStock(false);
        }
      });
    }
  }, [quotation]);

  const uploadFile = async (formData) => {
    await QuotationService.fileUpload(id, formData);
    getFile();
  };

  const deleteItem = async () => {
    await QuotationService.deleteFile(uuid, model_id);
    getFile();
  };

  const getFile = async () => {
    const res = await QuotationService.getFile(id);
    setFile(res);
  };

  const [totalAmount, setTotal] = useState(0);
  useEffect(() => {
    const sum = quotation?.service_items?.reduce(
      (partialSum, a) => parseInt(partialSum) + parseInt(a.amount),
      0
    );
    setTotal(sum);
  }, [quotation?.service_items]);

  useEffect(() => {
    if (id) getQuotation();
    getFile();
  }, [id]);

  //* Generating Invoice
  const storeInvoice = async () => {
    if (quotation?.status != "complete") {
      toast.warning("Please Complete the service!");
    } else {
      setBlock(true);
      let res = await InvoiceService.createService(quotation);
      setBlock(false);
      navigate(`/panel/services-invoice`);
    }
  };

  return (
    <div className="d-flex flex-column-fluid">
      <div className="container">
        <div className="row">
          <div className="col-xl-3">
            <div className="card card-custom">
              <div className="card-header">
                <div className="card-title">
                  <h3 className="card-label">
                    <button
                      className="btn btn-sm btn-dark "
                      style={{ marginRight: "0.75rem" }}
                      onClick={() => navigate(-1)}
                    >
                      <i className="fa fa-arrow-left"></i>Back
                    </button>
                    Details
                  </h3>
                </div>
              </div>

              <div className="card-body py-4">
                <div className="fw-bolder mt-5">Company</div>
                <div className="text-gray-600">
                  {quotation?.company?.name ?? "--"}
                </div>

                <div className="fw-bolder mt-5">RQ Number</div>

                <div className="text-gray-600">
                  {quotation?.pq_number ?? "--"}
                </div>

                <div className="fw-bolder mt-5">Engineer</div>
                <div className="text-gray-600">
                  {quotation?.engineer?.name ?? "--"}
                </div>

                <div className="fw-bolder mt-5">Servicing date</div>
                <div className="text-gray-600">
                  <Moment format="D MMMM YYYY">
                    {quotation?.expected_delivery}
                  </Moment>
                </div>

                <div className="fw-bolder mt-5">Account details</div>
                <div className="text-gray-600">
                  {quotation?.account_details ?? "--"}
                </div>

                <div className="fw-bolder mt-5">Priority</div>
                <div className="text-gray-600">
                  {quotation?.priority?.capitalize()}
                </div>

                <div className="fw-bolder mt-5">Type</div>
                <div className="text-gray-600">
                  {quotation?.type?.replaceAll("_", " ")?.capitalize()}
                </div>

                <div className="fw-bolder mt-5">Updated At</div>
                <div className="text-gray-600">
                  <Moment format="D MMMM YYYY">{quotation?.updated_at}</Moment>
                </div>

                <div className="fw-bolder mt-5">Ref Number</div>
                <div className="text-gray-600">
                  {quotation?.ref_number ?? "--"}
                </div>

                <div className="fw-bolder mt-5">Reason Of Trouble</div>
                <div className="text-gray-600">
                  {quotation?.reason_of_trouble ?? "--"}
                </div>

                <div className="fw-bolder mt-5">Solutions</div>
                <div className="text-gray-600">
                  {quotation?.solutions ?? "--"}
                </div>

                <div className="fw-bolder mt-5">Remarks</div>
                <div className="text-gray-600">{quotation?.status ?? "--"}</div>

                <div className="fw-bolder mt-5">Remarks</div>
                <div className="text-gray-600">
                  {quotation?.remarks ?? "--"}
                </div>

                <div className="card-title mt-10 justify-content-center">
                  <h3 className="card-label mr-10">
                    <PermissionAbility permission="service_quotations_partItems_update">
                    <button
                      className="btn btn-sm btn-dark"
                      onClick={() => {
                        setUpdateDueAMountModal(true);
                      }}
                    >
                      <i className="fa fa-pen"></i> Update Info
                    </button>
                    </PermissionAbility>
                  </h3>
                </div>
              </div>
              <div className="card-header">
                <div className="card-title">
                  <h3 className="card-label">
                    <PermissionAbility permission="service_quotations_print">
                      <Link
                        className="btn btn-sm btn-dark "
                        to={
                          "/panel/service-quotation/" + quotation.id + "/print"
                        }
                        style={{ marginRight: "0.75rem" }}
                        target="_blank"
                      >
                        Print
                      </Link>
                    </PermissionAbility>
                  </h3>
                  <>
                    <h3 className="card-label">
                      <PermissionAbility permission="service_quotations_generate_invoice">
                        <button
                          className="btn btn-sm btn-dark "
                          style={{ marginRight: "0.1rem" }}
                          onClick={() => {
                            storeInvoice();
                          }}
                        >
                          Generate Invoice
                        </button>
                      </PermissionAbility>
                    </h3>
                    {/* ) : (
                        <>
                          <PermissionAbility permission="requisitions_approve">
                            {requisition.status == "rejected" ? (
                              <h3 className="card-label">
                                <div className="btn btn-sm bg-danger disabled text-white">
                                  Rejected Requisition
                                </div>
                              </h3>
                            ) : (
                              <>
                                <h3 className="card-label">
                                  <button
                                    onClick={approveRequisition}
                                    className="btn btn-sm btn-primary"
                                  >
                                    Approve
                                  </button>
                                </h3>
                                <h3 className="card-label">
                                  <button
                                    onClick={rejectRequisition}
                                    className="btn btn-sm btn-danger"
                                  >
                                    Reject
                                  </button>
                                </h3>
                              </>
                            )}
                          </PermissionAbility>
                        </>
                      )} */}
                  </>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-9">
            <div className="flex-lg-row-fluid ms-lg-15">
              <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
                <li className="nav-item">
                  <a
                    className={`nav-link text-active-primary pb-4 ${
                      tab == "serviceItems" ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    href="#serviceItems"
                    onClick={() => setTab("serviceItems")}
                  >
                    Service Items
                  </a>
                </li>
                <PermissionAbility permission="service_quotations_files">
                <li className="nav-item">
                  <a
                    className={`nav-link text-active-primary pb-4 ${
                      tab == "Files" ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    href="#files"
                    onClick={() => setTab("files")}
                  >
                    Files
                  </a>
                </li>
                </PermissionAbility>


                <li className="nav-item">
                  <a
                    className={`nav-link text-active-primary pb-4 ${
                      tab == "activities" ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    href="#activities"
                    onClick={() => setTab("activities")}
                  >
                    Activities
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div
                  className={`tab-pane fade ${
                    tab == "serviceItems" ? "active show" : ""
                  }`}
                  id="serviceItems"
                  role="tabpanel"
                >
                  <div className="card card-custom gutter-b">
                    <div className="card-body px-0">
                      <div className="card mb-5 mb-xl-8">
                        <div className="card-body py-3">
                          <div className="table-responsive">
                            <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                              <thead>
                                <tr className="fw-bolder text-muted">
                                  <th className="min-w-50px">Service Name</th>
                                  <th className="min-w-120px">Amount</th>
                                </tr>
                              </thead>

                              <tbody>
                                {quotation?.service_items?.map(
                                  (item, index) => (
                                    <tr key={index}>
                                      <td className="">
                                        <Link
                                          to={"/panel/parts/" + item?.part?.id}
                                          className="text-dark fw-bolder text-hover-primary"
                                        >
                                          {item?.service?.name}
                                        </Link>
                                      </td>
                                      <td className=" fw-bolder mb-1 fs-6">
                                        <span>{parseInt(item?.amount)}</span>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            <div className="separator separator-dashed"></div>
                            <div className="text-gray-700 text-uppercase mt-5 text-end font-size h2">
                              Total Amount : {parseInt(totalAmount)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`tab-pane fade ${
                    tab == "files" ? "active show" : ""
                  }`}
                  id="files"
                  role="tabpanel"
                >
                  <div className="card card-custom gutter-b">
                    <div className="card-body px-0">
                      <div className="card mb-5 mb-xl-8">
                        <div className="card-body py-3">
                          <form
                            id="attachment-form"
                            encType="multipart/form-data"
                          >
                            <NewDropzone onDrop={uploadFile} />
                          </form>
                          <div className="table-responsive">
                            <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                              <thead>
                                <tr className="fw-bolder text-muted">
                                  <th className="min-w-50px">SL</th>
                                  <th className="min-w-120px">File Name</th>
                                  <th className="min-w-120px">Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {file?.data?.map((item, index) => (
                                  <tr key={index}>
                                    <td className="">{index + 1}</td>
                                    <td className=" fw-bolder mb-1 fs-6">
                                      <span>{item?.file_name}</span>
                                    </td>
                                    <td className=" fw-bolder mb-1 fs-6">
                                      <button
                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                        onClick={() => {
                                          setConfirmDelete(true);
                                          setuuid(item.uuid);
                                          setModelId(item.model_id);
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                      <a
                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                        href={item?.original_url}
                                        target="_blank"
                                      >
                                        <i className="fa fa-download"></i>
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Activities logName="quotations" modelId={id} tab={tab} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        open={confirmDelete}
        onConfirm={() => {
          setConfirmDelete(false);
          deleteItem();
        }}
        onCancel={() => setConfirmDelete(false)}
      />
      <UpdateReqInfo
        open={updateDueAMountModal}
        data={quotation}
        onCloseModal={onCloseModal}
        onUpdated={getQuotation}
      />
    </div>
  );
};

export default ShowQuotation;
