import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
const InvoiceServiceItems = ({ tab, active, type, serviceItems }) => {
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (data) {
      let total = 0;
      data?.map((item) => {
        total = parseInt(total) + parseInt(item?.amount);
      });
      setTotal(total);
    }
  }, [data]);

  useEffect(() => {
    if (serviceItems?.length) {
      setData(serviceItems);
    }
  }, [serviceItems]);
  return (
    <div className="d-flex flex-column gacompanyIdgap-lg-10">
      <div className="card card-custom gutter-b">
        <div className="card-header card-header-tabs-line">
          <div className="card-toolbar">
            <div className="card-title">
              <h3 className="card-label">Service Items</h3>
            </div>
          </div>
        </div>

        <div className="card-body px-0">
          <div className="card mb-2 mb-xl-8">
            <div className="card-body py-1">
              <div className="table-responsive">
                <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                  <thead>
                    <tr className="fw-bolder text-muted">
                      <th className="min-w-50px">Service Name</th>
                      <th className="min-w-120px">Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data?.map((item, index) => (
                      <tr key={index}>
                        <td className="">
                          <Link
                            to={"/panel/parts/" + item?.part?.id}
                            className="text-dark fw-bolder text-hover-primary"
                          >
                            {item?.service?.name}
                          </Link>
                        </td>
                        <td className=" fw-bolder mb-1 fs-6">
                          <span>{item?.amount}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="separator separator-dashed"></div>
                <div className="text-gray-700 text-uppercase mt-5 text-end font-size h2">
                  Total Amount : {total}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceServiceItems;
