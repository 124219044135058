import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Moment from "react-moment";
import DeliverNoteService from "services/DeliverNoteService";
import Barcode from "react-barcode";
const PrintDeliveryNotes = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [deliveryNote, setDeliveryNote] = useState(null);

  const getDeliveryNotes = async () => {
    let res = await DeliverNoteService.get(id);
    setDeliveryNote(res);
  };

  const [total, setTotal] = useState(null);
  useEffect(() => {
    let inTotal = 0;
    if (deliveryNote) {
      if (deliveryNote?.quotation?.type == "purchase_request") {
        deliveryNote?.part_items?.forEach((element) => {
          inTotal = inTotal + element?.unit_value * element?.quantity;
        });
      }
      setTotal(inTotal);
    }
  }, [deliveryNote]);

  useEffect(() => {
    if (total != null) {
      let content = document.getElementById("content").innerHTML;
      document.body.innerHTML = content;
      setTimeout(() => {
        window.print();
      }, 500);
    }
  }, [total]);

  useEffect(() => {
    if (id) getDeliveryNotes();
  }, [id]);
  return (
    <div className="post" id="content">
      {deliveryNote && (
        <div className="container-xxl">
          <div className="card">
            <div className="card-body py-10">
              <div className="mw-lg-950px mx-auto w-100">
                <div className="">
                  <div className="text-center">
                    <h1>Delivery Note</h1>
                  </div>
                  <table width="100%">
                    <tr>
                      <td>
                        <h6>
                          <strong>Company Name: </strong>
                          <span className="text-muted">
                            {deliveryNote?.company?.name}
                          </span>
                        </h6>

                        <h6>
                          <strong>Company Email: </strong>
                          <span className="text-muted">
                            {deliveryNote.company?.email}
                          </span>
                        </h6>

                        {/* <h6>
                        <strong>Issued By: </strong>
                        <span className="text-muted">
                          {deliveryNote?.created_by}
                        </span>
                      </h6> */}
                      </td>
                      <td style={{ marginLeft: "120px" }} width="20%"></td>

                      <td style={{ marginLeft: "120px" }}>
                        <h6>
                          <strong>Date: </strong>
                          <span className="text-muted">
                            <Moment format="D MMMM YYYY">
                              {deliveryNote?.created_at}
                            </Moment>
                          </span>
                        </h6>
                        <h6>
                          <strong>Phone: </strong>
                          <span className="text-muted">
                            {deliveryNote?.company?.tel}
                          </span>
                        </h6>
                      </td>
                    </tr>
                  </table>

                  {/* Machine Problems */}
                  <div className="d-flex justify-content-between flex-column flex-md-row">
                    <div className="flex-grow-1">
                      <h6>
                        Requisition Type:{" "}
                        <span className="text-muted">
                          {deliveryNote?.quotation?.type
                            ?.replaceAll("_", " ")
                            ?.capitalize()}
                        </span>
                      </h6>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between flex-column flex-md-row">
                    <div className="flex-grow-1 pt-2">
                      <div className="table-responsive ">
                        <table className="table">
                          <thead className="m-20">
                            <tr className="fs-6 fw-bolder text-dark text-uppercase">
                              <th className="text-center">
                                <div
                                  className="p-1"
                                  style={{
                                    backgroundColor: "#FD7E14",
                                    color: "#fff",
                                  }}
                                >
                                  {" "}
                                  SL.No{" "}
                                </div>
                              </th>
                              <th className="text-start w-50 ">
                                <div
                                  className="p-1"
                                  style={{
                                    backgroundColor: "#009EF7",
                                    color: "#fff",
                                  }}
                                >
                                  {" "}
                                  Part Name{" "}
                                </div>
                              </th>
                              <th className="text-center">
                                <div
                                  className="p-1"
                                  style={{
                                    backgroundColor: "#FD7E14",
                                    color: "#fff",
                                  }}
                                >
                                  {" "}
                                  Number{" "}
                                </div>
                              </th>
                              <th className="text-center">
                                <div
                                  className="p-1"
                                  style={{
                                    backgroundColor: "#FD7E14",
                                    color: "#fff",
                                  }}
                                >
                                  {" "}
                                  Quantity{" "}
                                </div>
                              </th>
                              <th className="text-center">
                                <div
                                  className="p-1"
                                  style={{
                                    backgroundColor: "#009EF7",
                                    color: "#fff",
                                  }}
                                >
                                  {" "}
                                  Price{" "}
                                </div>
                              </th>
                              <th className="text-center">
                                <div
                                  className="p-1"
                                  style={{
                                    backgroundColor: "#009EF7",
                                    color: "#fff",
                                  }}
                                >
                                  {" "}
                                  Total{" "}
                                </div>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {deliveryNote?.part_items?.length &&
                              deliveryNote?.part_items?.map((item, idx) => {
                                return (
                                  <tr
                                    className="text-dark border-bottom border-1 border-dark"
                                    key={idx}
                                  >
                                    <td className=" text-center">{idx + 1}</td>
                                    <td className="text-start">
                                      {" "}
                                      <h6>{item?.part?.part_name?.name}</h6>
                                    </td>
                                    <td>
                                      <div>{item?.part?.part_name?.part_number}</div>
                                    </td>
                                    <td className="text-center">
                                      {item?.quantity}
                                    </td>
                                    <td className=" text-center">
                                      {" "}
                                      {item?.unit_value}{" "}
                                    </td>
                                    <td className=" text-center">
                                      {" "}
                                      {item?.quantity * item?.unit_value}{" "}
                                    </td>
                                  </tr>
                                );
                              })}
                            <tr className="text-dark ">
                              <td colSpan={3}></td>
                              <td
                                colSpan={2}
                                className="text-dark border-bottom border-1 border-dark"
                              >
                                <h6>Total</h6>
                              </td>
                              <td className="text-center text-dark border-bottom border-1 border-dark">
                                {total}
                              </td>
                            </tr>
                            <tr className="text-dark ">
                              <td colSpan={3}></td>
                              <td
                                colSpan={2}
                                className="text-dark border-bottom border-1 border-dark"
                              >
                                <h6>
                                  Vat({deliveryNote?.part_items[0]?.vat}%)
                                </h6>
                              </td>
                              <td className="text-center text-dark border-bottom border-1 border-dark">
                                {(total * deliveryNote?.part_items[0]?.vat) /
                                  100}
                              </td>
                            </tr>
                            <tr className="text-dark ">
                              <td colSpan={3}></td>
                              <td
                                colSpan={2}
                                className="text-dark border-bottom border-1 border-dark"
                              >
                                <h6>
                                  Discount(
                                  {deliveryNote?.part_items[0]?.discount}
                                  %)
                                </h6>
                              </td>
                              <td className="text-center text-dark border-bottom border-1 border-dark">
                                {(total *
                                  deliveryNote?.part_items[0]?.discount) /
                                  100}
                              </td>
                            </tr>
                            <tr className="text-dark">
                              <td colSpan={3}></td>
                              <td
                                colSpan={2}
                                className="text-dark border-bottom border-1 border-dark"
                              >
                                <h6>Grand Total</h6>
                              </td>
                              <td className="text-center text-dark border-bottom border-1 border-dark">
                                {Math.floor(
                                  total *
                                    (1 +
                                      (deliveryNote?.part_items[0]?.vat -
                                        deliveryNote?.part_items[0]?.discount) /
                                        100)
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* <div className="d-flex justify-content-between flex-column flex-md-row">
                <div className="flex-grow-1 pt-2">
                  <div className="table-responsive ">
                    <p>
                      Terms & conditions :<br />
                      Brand : Tajima <br />
                      Origin: Japan
                      <br />
                      Payment mode: Cash or cheque have to be paid before parts
                      delivery <br />
                      validity:This requisition is valid for 07 days . Price might
                      vary after validity period expired. <br />
                      <br />
                      Feel free to contact us, if you need any further
                      clarification/information or comments related to this
                      Mail.
                      <br />
                      <br />
                      Thanks & Best Regards
                      <br />
                      <br />
                      Safil Nawaz Chowdhury
                      <br />
                      Deputy Managing Director
                      <br />
                      NAF GROUP (Stitch & Color Technology)
                      <br />
                      Mail ID: safil@nafgroup.org
                      <br />
                      01919331919
                    </p>
                  </div>
                </div>
              </div> */}

                  <div className="fixed-bottom mb-10 text-center border-top border-1 border-dark mt-3">
                    <div className="d-flex flex-row justify-content-evenly">
                      <div className="d-flex flex-row">
                        <div className="m-2 p-2 border border-1 rounded-circle border-dark">
                          <span>
                            <img
                              src="https://img.icons8.com/ios/344/city-buildings.png"
                              style={{ height: 35, weight: 35 }}
                            />
                          </span>
                        </div>
                        <div className="pt-2 text-start">
                          <h4>Dhaka Office</h4>
                          <span>H# 8/1, R#4, Metro Development</span>
                          <br />
                          <span>Housing Ltd. Mohammadpur, Dhaka</span>
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="m-2 p-2 border border-1 rounded-circle border-dark">
                          <span>
                            <img
                              src="https://img.icons8.com/ios/344/city-buildings.png"
                              style={{ height: 35, weight: 35 }}
                            />
                          </span>
                        </div>
                        <div className="pt-2 text-start">
                          <h4>Chittagong Office</h4>
                          <span>H# 67A, R# 28A, B# C</span>
                          <br />
                          <span>Nizam Road, Panchlaish, Chittagong</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrintDeliveryNotes;
