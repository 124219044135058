import Table from "components/utils/Table";
import PermissionAbility from "helpers/PermissionAbility";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DeliverNoteService from "services/DeliverNoteService";
import InvoiceService from "services/InvoiceService";
import InvoiceFilter from "./InvoiceFilter";

const Invoices = () => {
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [filter, setFilter] = useState(false);
  const [block, setBlock] = useState(false);

  const filterdata = (data) => {
    setFilter(false);
    getInvoices(data);
  };

  const getInvoices = async (filters) => {
    setInvoices(await InvoiceService.getAll(filters));
    setLoading(false);
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.invoice_number,
      sortable: true,
      field: "id",
    },
    {
      name: "Company",
      selector: (row) => row?.company?.name,
      sortable: true,
      field: "name",
      format: (row) => (
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column">
            <Link
              to={"/panel/companies/" + row?.company?.id}
              className="text-dark fw-bolder text-hover-primary"
            >
              {row?.company?.name}
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: "Requisition Type",
      selector: (row) =>
        row?.previous_due
          ? "Previous Due"
          : row?.type?.replaceAll("_", " ")?.capitalize(),
      sortable: true,
      field: "id",
    },
    {
      name: "Part Quantity",
      selector: (row) =>
        row?.part_items?.reduce((partialSum, a) => partialSum + a.quantity, 0),
      format: (row) => (
        <div className="mt-2">
          {row?.previous_due
            ? "N/A"
            : row?.part_items?.reduce(
                (partialSum, a) => partialSum + a.quantity,
                0
              )}
        </div>
      ),
      sortable: true,
      field: "expected_delivery",
    },

    {
      name: "Total",
      selector: (row) => row?.previous_due,
      sortable: true,
      field: "total_due",
      format: (row) => (
        <div className="mt-2">{row?.previous_due ?? row?.totalAmount} tk</div>
      ),
    },

    {
      name: "Paid",
      selector: (row) => row?.totalPaid,
      sortable: true,
      field: "total_due",
      format: (row) => (
        <div className="mt-2">
          {row?.totalPaid ? parseInt(row?.totalPaid) : 0} tk
        </div>
      ),
    },

    {
      name: "DN number",
      selector: (row) => row?.deliveryNote?.dn_number,
      sortable: true,
      field: "role",
      format: (row) => (
        <div className="mt-2">
          {row?.previous_due
            ? "N/A"
            : row?.deliveryNote?.dn_number
            ? row?.deliveryNote?.dn_number
            : "No delivery note yet"}
        </div>
      ),
    },

    {
      name: "Action",
      selector: (row) => row.status,
      format: (row) => (
        <>
          <span className="text-end">
            <PermissionAbility permission="invoices_show">
              <Link
                to={"/panel/invoices/" + row.id}
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              >
                <i className="fa fa-eye"></i>
              </Link>
            </PermissionAbility>
          </span>
          {!row?.previous_due && (
            <>
              <span className="text-end">
                <PermissionAbility permission="invoices_print">
                  <Link
                    to={"/panel/invoices/" + row.id + "/print"}
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                    target="_blank"
                  >
                    <i className="fa fa-print"></i>
                  </Link>
                </PermissionAbility>
              </span>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <Table
            name="Quotations"
            buttonName="Filter"
            onClickButton={() => {
              setFilter(!filter);
            }}
            isLoading={loading}
            data={invoices}
            columns={columns}
            onFilter={getInvoices}
            buttonPermission="invoices_create"
          />
        </div>
      </div>
      <InvoiceFilter
        enable={filter}
        onClickOutside={() => {
          setFilter(!filter);
        }}
        onChange={(data) => {
          filterdata(data);
        }}
      />
    </>
  );
};

export default Invoices;
